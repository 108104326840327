
import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Table, message } from 'antd';

export default function PosterManagement() {
  const [form] = Form.useForm();
  const [posters, setPosters] = useState([]);

  // Funzione per recuperare i poster dal server
  const fetchPosters = async () => {
    try {
      const response = await fetch('https://hiddenvideos.vip/api/telegram-posters', {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        throw new Error('Errore nel recupero dei poster');
      }
      const data = await response.json();
      setPosters(data);
    } catch (error) {
      message.error('Errore nel recupero dei poster');
    }
  };

  // Carica i poster al montaggio del componente
  useEffect(() => {
    fetchPosters();
  }, []);

  // Funzione per aggiungere un nuovo poster
  const onFinish = async (values) => {
    try {
      const response = await fetch('https://hiddenvideos.vip/api/telegram-posters', {
        method: 'POST',
        headers: {
          'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({ telegramId: values.telegramId })
      });
      const data = await response.json();
      if (!response.ok) throw new Error(data.message);
      message.success('Poster aggiunto con successo');
      form.resetFields();
      fetchPosters();
    } catch (error) {
      message.error('Errore durante l\'aggiunta del poster');
    }
  };

  // Funzione per eliminare un poster
  const handleDelete = async (telegramId) => {
    try {
      const response = await fetch('https://hiddenvideos.vip/api/telegram-posters', {
        method: 'POST',
        headers: {
          'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({ telegramId, action: 'delete' })
      });
      const data = await response.json();
      if (!response.ok) throw new Error(data.message);
      message.success('Poster eliminato con successo');
      fetchPosters();
    } catch (error) {
      message.error('Errore durante l\'eliminazione del poster');
    }
  };

  // Definizione delle colonne per la tabella degli ID
  const columns = [
    {
      title: 'Telegram ID',
      dataIndex: 'telegramId',
      key: 'telegramId'
    },
    {
      title: 'Azione',
      key: 'action',
      render: (_, record) => (
        <Button type="link" danger onClick={() => handleDelete(record.telegramId)}>
          Elimina
        </Button>
      )
    }
  ];

  return (
    <div style={{ padding: 20 }}>
      <h2>Gestione Poster Telegram</h2>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="telegramId"
          label="Telegram ID"
          rules={[{ required: true, message: 'Inserisci l\'ID di Telegram' }]}
        >
          <Input placeholder="Inserisci l'ID di Telegram" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Aggiungi Poster
          </Button>
        </Form.Item>
      </Form>
      <Table
        style={{ marginTop: 20 }}
        dataSource={posters.map((id) => ({ telegramId: id }))}
        columns={columns}
        rowKey="telegramId"
      />
    </div>
  );
}
