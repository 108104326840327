import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  Paper,
} from "@mui/material";



export default function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const response = await fetch('https://hiddenvideos.vip/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': '=1l<*uq8Ks8Fhxg5-]b'
        },
        credentials: 'include',
        body: JSON.stringify({ username, password })
      });
      if (!response.ok) throw new Error("Credenziali errate");
      navigate('/dashboard/cards');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "linear-gradient(135deg, #000, #1a1a1a)",
        p: 2,
      }}
    >
      <Paper
        elevation={6}
        sx={{
          p: 4,
          maxWidth: 400,
          width: "100%",
          borderRadius: 2,
          background: "rgba(255,255,255,0.05)",
          backdropFilter: "blur(8px)",
          textAlign: "center",
        }}
      >
        <Typography variant="h4" sx={{ mb: 3, color: "#fff" }}>
          Login Admin
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <Box
          component="form"
          onSubmit={handleLogin}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <TextField
            label="Username"
            variant="filled"
            fullWidth
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            InputProps={{
              sx: {
                backgroundColor: "rgba(255,255,255,0.1)",
                borderRadius: 1,
                color: "#fff",
              },
            }}
            InputLabelProps={{ sx: { color: "#fff" } }}
          />
          <TextField
            label="Password"
            variant="filled"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              sx: {
                backgroundColor: "rgba(255,255,255,0.1)",
                borderRadius: 1,
                color: "#fff",
              },
            }}
            InputLabelProps={{ sx: { color: "#fff" } }}
          />
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              py: 1.5,
              fontSize: "1rem",
              backgroundColor: "#FF4081",
              "&:hover": { backgroundColor: "#e73370" },
            }}
          >
            Login
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}
