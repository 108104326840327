import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box
} from "@mui/material";

/**
 * ClickableCard
 * @param {string} image - URL dell'immagine
 * @param {string} title - Titolo da mostrare sul bottone
 * @param {function} onClick - Funzione da eseguire al click
 */
const ClickableCard = ({ image, title, onClick }) => {
  return (
    <Card
      sx={{
        width: 320,
        height: 400,
        m: 2,
        display: "flex",
        flexDirection: "column",
        borderRadius: 3,
        // Gradiente scuro di sfondo
        background: "linear-gradient(145deg, #0d0d0d, #1c1c1c)",
        boxShadow: "0 2px 8px rgba(0,0,0,0.6)",
        overflow: "hidden",
        position: "relative",
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
        "&:hover": {
          transform: "translateY(-4px)",
          boxShadow: "0 4px 16px rgba(255, 0, 0, 0.3)",
        },
      }}
    >
      <CardActionArea
        onClick={onClick}
        sx={{
          flexGrow: 1,
          overflow: "hidden",
          position: "relative",
          "&:hover img": {
            transform: "scale(1.05)",
          },
        }}
      >
        <CardMedia
          component="img"
          alt={title}
          image={image}
          sx={{
            height: 260,
            objectFit: "cover",
            transition: "transform 0.3s ease",
            transformOrigin: "center",
          }}
        />
      </CardActionArea>

      <CardContent
        sx={{
          p: 2,
          // Leggero gradiente dietro al contenuto (opzionale)
          background: "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 70%)",
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        {/* Se vuoi un breve testo descrittivo sopra il bottone, scommentalo: */}
        {/* <Typography variant="subtitle1" sx={{ color: "#fff", fontWeight: 500 }}>
          {title}
        </Typography> */}

        <Button
          onClick={onClick}
          fullWidth
          sx={{
            textTransform: "none",
            fontWeight: 600,
            color: "#fff",
            borderRadius: 2,
            // Gradiente rosso
            background: "linear-gradient(135deg, #ff0000, #b30000)",
            boxShadow: "none",
            transition: "all 0.3s ease",
            "&:hover": {
              background: "linear-gradient(135deg, #e60000, #990000)",
              transform: "translateY(-1px)",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
            },
          }}
        >
          {title}
        </Button>
      </CardContent>
    </Card>
  );
};

export default ClickableCard;
