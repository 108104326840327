

import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, message } from 'antd';

export default function Cards() {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingCard, setEditingCard] = useState(null);
  const [form] = Form.useForm();

  // Recupera le card dall'API
  const fetchCards = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://hiddenvideos.vip/api/cards', {
        method: 'GET',
        headers: {
          Authorization: '=1l<*uq8Ks8Fhxg5-]b',
          'Content-Type': 'application/json'
        },
      });
      const data = await response.json();
      setCards(data);
    } catch (error) {
      message.error('Errore nel recupero delle card');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCards();
  }, []);

  // Apertura della modal per la modifica della card
  const handleEdit = (record) => {
    setEditingCard(record);
    form.setFieldsValue({
      title: record.title || '',
      category: record.category || '',
      imageUrl: record.imageUrl || '',
      link: record.link || ''
    });
  };

  // Eliminazione della card
  const handleDelete = async (id) => {
    try {
      await fetch(`https://hiddenvideos.vip/api/cards/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: '=1l<*uq8Ks8Fhxg5-]b',
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      });
      message.success('Card eliminata');
      fetchCards();
    } catch (error) {
      message.error('Errore durante l\'eliminazione');
    }
  };

  // Aggiornamento della card tramite la modal
  const handleUpdate = async () => {
    try {
      const values = await form.validateFields();
      await fetch(`https://hiddenvideos.vip/api/cards/${editingCard.id}`, {
        method: 'PUT',
        headers: {
          Authorization: '=1l<*uq8Ks8Fhxg5-]b',
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(values)
      });
      message.success('Card aggiornata');
      setEditingCard(null);
      fetchCards();
    } catch (error) {
      message.error('Errore durante l\'aggiornamento');
    }
  };

  // Definizione delle colonne senza il campo "Type"
  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'Title', dataIndex: 'title', key: 'title' },
    { title: 'Category', dataIndex: 'category', key: 'category' },
    {
      title: 'Image URL',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      render: (url) =>
        url ? (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
        ) : (
          '-'
        )
    },
    {
      title: 'Link',
      dataIndex: 'link',
      key: 'link',
      render: (link) =>
        link ? (
          <a href={link} target="_blank" rel="noopener noreferrer">
            {link}
          </a>
        ) : (
          '-'
        )
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <>
          <Button onClick={() => handleEdit(record)}>Modifica</Button>
          <Button danger onClick={() => handleDelete(record.id)} style={{ marginLeft: 8 }}>
            Elimina
          </Button>
        </>
      )
    }
  ];

  return (
    <div style={{ padding: 20, overflowX: 'auto' }}>
      <h2>Lista Card</h2>
      <Table
        dataSource={cards}
        columns={columns}
        rowKey="id"
        loading={loading}
        scroll={{ x: 600 }} // Permette lo scroll orizzontale su schermi piccoli
      />
      <Modal
        title="Modifica Card"
        visible={!!editingCard}
        onOk={handleUpdate}
        onCancel={() => setEditingCard(null)}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: 'Inserisci il titolo' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="category"
            label="Category"
            rules={[{ required: true, message: 'Inserisci la categoria' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="imageUrl"
            label="Image URL"
            rules={[{ required: true, message: 'Inserisci l\'URL dell\'immagine' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="link"
            label="Link"
            rules={[{ required: true, message: 'Inserisci il link' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
