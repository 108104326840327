import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Link,
  Typography,
  Menu,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Chip,
  Divider,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import TelegramIcon from '@mui/icons-material/Telegram';
import MenuIcon from '@mui/icons-material/Menu'; // Icona per il menu a discesa
import { Link as RouterLink } from 'react-router-dom'; // Link di react-router-dom
import { useNavigate } from 'react-router-dom'; // Hook per la navigazione
import './css/menu.css'; // Importa il file CSS per gli stili
import CryptoJS from 'crypto-js';
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import Btn from './btn';
import BtnSky from './btnSky';

import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import FastfoodIcon from "@mui/icons-material/Fastfood";

function md5ToBase64(url) {
  // Step 1: Convert the URL to an MD5 hash
  const md5Hash = CryptoJS.MD5(url).toString();
  
  // Step 2: Create an array with the hash and the additional value
  const array = [md5Hash, "22dcbe26d606977f9484257ea466af9254979b97"];
  
  // Step 3: Convert the array to a JSON string
  const jsonString = JSON.stringify(array);
  
  // Step 4: Encode the JSON string to base64
  const base64String = btoa(jsonString);
  
  return base64String;
}



// Definisci il componente MenuComponent
const MenuComponent = () => {
  const [randomCard, setRandomCard] = useState(null); // Modifica da [] a null
  const navigate = useNavigate(); // Hook per la navigazione
  const [anchorEl, setAnchorEl] = useState(null);
const [mobileOpen, setMobileOpen] = useState(false);
const [redditLink, setRedditLink] = useState('');
const [selectedRoute, setSelectedRoute] = useState("");

const fetchRandomCard = async () => {
    try {
      const response = await fetch(`https://hiddenvideos.vip/api/random`, {
        method: 'GET',
        headers: {
          'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setRandomCard(data);

      if (data.id) {
           let param = md5ToBase64(`https://hiddenvideos.vip/card/${data.id}`)
        navigate(`/card/${data.id}?u=${param}`);
      }
    } catch (error) {
      console.error('Error fetching random card:', error);
    }
  };

const handleOpenMenu = (event) => {
    setSelectedRoute(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setSelectedRoute(null);
  };

  const handleMenuItemClick = (path) => {
    handleCloseMenu();
    navigate(path);
  };

  const handleMenuClick = (event) => {
    setSelectedRoute(event.currentTarget);
  };

const [open, setOpen] = useState(false);
  const handleToggle = () => setOpen((prev) => !prev);


  // Gestore per chiudere il menu a discesa
  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

   const loadRedditLink = async () => {
    try {
      const response = await fetch('https://hiddenvideos.vip/api/reddit'); // GET
      if (!response.ok) throw new Error('Errore nel recupero del link Reddit');
      const data = await response.json();
      if (data.redditLink) {
        setRedditLink(data.redditLink);
      }
    } catch (error) {
      console.error(error);
    }
  };

  loadRedditLink()

  const [value, setValue] = useState('');

  // Valore predefinito da usare se non è presente nel localStorage
  const defaultValue = 0;

  // Leggi o imposta il valore nel localStorage all'inizio
  useEffect(() => {
  const coinKey = 'fd064b41a01ca9a573feb720493a334d';
  const lastUpdateKey = 'e5e486979728b4f6be6401d24967ecc0';
  const defaultCoins = 3;

  // Leggi il valore salvato dal localStorage per i coin
  const storedCoins = localStorage.getItem(coinKey);
  // Leggi la data dell'ultimo aggiornamento
  const storedDate = localStorage.getItem(lastUpdateKey);

  // Se non ci sono coin salvati, usa il valore predefinito
  let coins = storedCoins ? parseInt(storedCoins, 10) : defaultCoins;

  // Ottieni la data di oggi in formato YYYY-MM-DD
  const today = new Date().toISOString().split("T")[0];

  // Se non c'è la data salvata o se la data salvata è diversa da oggi,
  // aggiungi 1 coin e aggiorna la data di aggiornamento
  if (!storedDate || storedDate !== today) {
    coins += 1;
    localStorage.setItem(lastUpdateKey, today);
    localStorage.setItem(coinKey, coins.toString());
  }

  // Imposta lo stato con il valore aggiornato
  setValue(coins);
}, []);

 const unifiedButtonStyle = {
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: "80px",
  height: "40px",
  borderRadius: "6px",
  textDecoration: "none",
  color: "#fff",
  // Semplice gradiente scuro
  background: "linear-gradient(135deg, #444 0%, #222 100%)",
  // Leggero bordo e ombra
  border: "1px solid #333",
  boxShadow: "0 2px 6px rgba(0, 0, 0, 0.6)",

  // Transizione fluida su hover
  transition: "transform 0.2s, box-shadow 0.2s, background 0.2s",

  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.7)",
    background: "linear-gradient(135deg, #555 0%, #333 100%)",
  },

  // Facoltativo: cursore “pointer”
  cursor: "pointer",
};
 const telegramCircleButtonStyle = {
  width: "35px",
  height: "35px",
  borderRadius: "50%",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  textDecoration: "none",
  // Gradiente Telegram
  background: "linear-gradient(135deg, #54B6EB 0%, #0088cc 100%)",
  color: "#fff",
  border: "none",
  cursor: "pointer",
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.5)",

  // Transizioni in hover
  transition: "transform 0.2s, box-shadow 0.2s",
"@keyframes canguroJump": {
    "0%":   { transform: "translateY(0)" },
    "20%":  { transform: "translateY(-5px)" },
    "40%":  { transform: "translateY(0)" },
    "60%":  { transform: "translateY(-2px)" },
    "100%": { transform: "translateY(0)" },
  },

  // In hover: effetto saltello
  "&:hover": {
    animation: "canguroJump 0.5s linear",
    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.6)",
  },

};



  // Contenuto del Drawer per mobile
  const drawer = (
    <Box
      sx={{
        width: 250,
        background: "#1a1a1a",
        height: "100%",
        color: "#fff",
      }}
      role="presentation"
      onClick={handleDrawerToggle}
      onKeyDown={handleDrawerToggle}
    >
      <Box sx={{ p: 2, borderBottom: "1px solid #333" }}>
        <Link
          component={RouterLink}
          to="/"
          underline="none"
          color="inherit"
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/favicon.jpeg`}
            alt="Logo"
            style={{ width: 36, height: 36, borderRadius: "8px" }}
          />
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            HiddenVideos
          </Typography>
        </Link>
      </Box>
      <List>

        <ListItem button component={RouterLink} to="/category">
           <Btn>
               Category
              </Btn>
        </ListItem>
        <ListItem button component={RouterLink} to="/how-to-get-coins">
                <Btn>
                How To Get Coins
              </Btn>
        </ListItem>
        <ListItem button component={RouterLink} to="/models">
                <Btn>
                Models
              </Btn>
        </ListItem>
         <ListItem button component={RouterLink} onClick={fetchRandomCard}>
                <Btn>
                <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginRight: "4px" }}
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-dices h-4 w-4 inline-block mr-2"
              >
                <rect width="12" height="12" x="2" y="10" rx="2" ry="2"></rect>
                <path d="m17.92 14 3.5-3.5a2.24 2.24 0 0 0 0-3l-5-4.92a2.24 2.24 0 0 0-3 0L10 6"></path>
                <path d="M6 18h.01"></path>
                <path d="M10 14h.01"></path>
                <path d="M15 6h.01"></path>
                <path d="M18 9h.01"></path>
              </svg>
              Random
              </Btn>
        </ListItem>
        <ListItem button component={RouterLink} to={redditLink} underline="none" target="_blank">
           <Btn
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  px: 1.5,
                  py: 0.5,
                  fontWeight: "bold",
                  backgroundColor: "#FF4081",
                  borderRadius: "8px",
                  textTransform: "uppercase",
                }}
              >
                <Typography sx={{ fontSize: "1.5rem", mt: "-4px" }}>+</Typography>
                REQUEST
              </Btn>
        </ListItem>
        <ListItem
  button

>
  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
    {/* Pulsante Coin Badge (apre Drawer) */}
    <Box
      onClick={handleToggle}
      sx={{ ...unifiedButtonStyle, color: "#FFD700" }}
    >
      <Typography variant="body1" sx={{ fontWeight: 600, mr: 1 }}>
        {value}
      </Typography>
      <MonetizationOnIcon sx={{ fontSize: "20px" }} />
    </Box>

    {/* Telegram Button */}
    <Box
      component="a"
      href="https://t.me/OFPlusHub"
      target="_blank"
      rel="noopener noreferrer"
      sx={telegramCircleButtonStyle}
    >
      <TelegramIcon sx={{ fontSize: 24 }} />
    </Box>
  </Box>

</ListItem>

      </List>

    </Box>
  );

  return (
    <>
      <AppBar
        position="sticky"
  sx={{
    top: 0,
    background: "linear-gradient(90deg, #000, #1a1a1a 100%)",
    boxShadow: "none",
    borderBottom: "1px solid #333",
  }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 2,
          }}
        >
          {/* Sezione sinistra: Logo, (titolo nascosto su mobile) e REQUEST */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Link
              component={RouterLink}
              to="/"
              underline="none"
              color="inherit"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <IconButton color="inherit" sx={{ p: 0 }}>
                <img
                  src={`${process.env.PUBLIC_URL}/favicon.jpeg`}
                  alt="Logo"
                  style={{ width: 36, height: 36, borderRadius: "8px" }}
                />
              </IconButton>
            </Link>
            {/* Titolo visualizzato solo su desktop */}
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <Link
                component={RouterLink}
                to="/"
                underline="none"
                color="inherit"
                sx={{ fontWeight: "bold", fontSize: "1.8rem" }}
              >
                HiddenVideos
              </Link>
            </Box>
            {/* Link REQUEST: visibile solo da md in su */}
<Link
  component={RouterLink}
  to={redditLink}
  underline="none"
  target="_blank"
  sx={{
    display: { xs: "none", md: "flex" }  // Nascondi su mobile
  }}
>
  <Btn
    sx={{
      display: "flex",
      alignItems: "center",
      gap: 1,
      px: 1.5,
      py: 0.5,
      fontWeight: "bold",
      backgroundColor: "#FF4081",
      borderRadius: "8px",
      textTransform: "uppercase",
    }}
  >
    <Typography sx={{ fontSize: "1.5rem", mt: "-4px" }}>+</Typography>
    REQUEST
  </Btn>
</Link>

          </Box>

          {/* Sezione destra: Coin Badge e Telegram (sempre visibili) */}
<Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      {/* Pulsante Coin Badge (apre Drawer) */}
      <Box
        onClick={handleToggle}
        sx={{...unifiedButtonStyle, color: "#FFD700",}}
      >
        <Typography variant="body1" sx={{ fontWeight: 600, mr: 1 }}>
          {value}
        </Typography>
        <MonetizationOnIcon sx={{ fontSize: "20px" }} />
      </Box>


            {/* Telegram Button "https://t.me/OFPlusHub"
 */}
            {/* Telegram Button: visibile solo da md in su */}
<Box
  component="a"
  href="https://t.me/OFPlusHub"
  target="_blank"
  rel="noopener noreferrer"
  sx={{
    ...telegramCircleButtonStyle,
    display: { xs: "none", md: "flex" }  // Nascondi su mobile
  }}
>
  <TelegramIcon sx={{ fontSize: 24 }} />
</Box>

          </Box>

          {/* Hamburger per mobile */}
          <IconButton
            color="inherit"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{ display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Drawer Mobile */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: 250,
            background: "#1a1a1a",
            color: "#fff",
          },
        }}
      >
        {drawer}
      </Drawer>

        {/* Drawer a destra */}
 <Drawer
     anchor="right"
  open={open}
  onClose={handleToggle}
  // Forza la direzione dello Slide
  TransitionProps={{
    direction: 'left',
  }}
  sx={{backgroundColor: "rgba(13,13,13,0.5) !important","& .MuiDrawer-paper": {

            background: "#0d0d0d",
          },}}

 PaperProps={{
        sx: {
          width: 420,
          color: "#fff",
          borderLeft: "1px solid #444",
          background: "linear-gradient(180deg, #0d0d0d 0%, #1a1a1a 100%)",
          boxShadow: "0 0 10px rgba(0,0,0,0.8)",

        },
  }}
      >
       <Box>

        <IconButton onClick={handleToggle} sx={{ color: "#fff" }}>
          <CloseIcon />
        </IconButton>
      </Box>

      {/* CONTENUTO SCORREVOLE DEL DRAWER */}
<Box sx={{ p: 2, pb: 8 }}>
  {/* Sezione utente */}
  <Box
    sx={{
      border: "1px solid #333",
      borderRadius: 2,
      p: 2,
      mb: 2,
    }}
  >
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <Typography variant="body1" sx={{ fontWeight: 600 }}>
        Anonymous
      </Typography>
      <Chip
        label="Basic"
        sx={{
          backgroundColor: "#00a300",
          color: "#fff",
          height: "20px",
          fontSize: "0.75rem",
        }}
      />
    </Box>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
         justifyContent: "center",
        color: "#FFD700",
        gap: 0.5,
        mt: 1,
      }}
    >
      <MonetizationOnIcon sx={{ fontSize: 20 }} />
      <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: 20}}>
        {value}
      </Typography>
    </Box>
  </Box>

  <Divider sx={{ mb: 2, borderColor: "#333" }} />

  {/* Sezione testo: "How do credits work?" */}
  <Box sx={{ mb: 2 }}>
    {/* Primo titolo */}
    <Typography
      variant="h6"
      sx={{
        fontWeight: "bold",
        mb: 0.5,
        fontSize: 22,
        color: "#fff",
      }}
    >
      How do credits work?
    </Typography>
    <Divider
      sx={{
        backgroundColor: "#666",
        mb: 2,
        opacity: 0.8,
        borderBottomWidth: "1px",
      }}
    />

    <Typography
      variant="body2"
      sx={{ mb: 3, lineHeight: 1.7, textAlign: "justify" }}
    >
      Credits will allow you to automatically unlock content without
      having to do any process outside the site, we introduced them to
      provide you with a reward!
    </Typography>

    {/* Secondo titolo */}
    <Typography
      variant="h6"
      sx={{
        fontWeight: "bold",
        mb: 0.5,
        fontSize: 22,
        color: "#fff",
      }}
    >
      How do I get the credits?
    </Typography>
    <Divider
      sx={{
        backgroundColor: "#666",
        mb: 2,
        opacity: 0.8,
        borderBottomWidth: "1px",
      }}
    />

    <Typography
      variant="body2"
      sx={{ lineHeight: 1.7, textAlign: "justify" }}
    >
      The accumulation of credits does not require any payment, you
      will be able to unlock them for free! You just need to use the
      website and unlock content... every time 5 content is unlocked
      automatically you will get a credit!
    </Typography>
  </Box>
</Box>


<Box sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <Button
        variant="text"
        onClick={handleOpenMenu}
        endIcon={<ArrowDropDownIcon />}
        sx={{
          color: "#ccc",       // testo grigio chiaro
          backgroundColor: "#111",
          borderRadius: 2,
          textTransform: "none",
          fontWeight: 500,
          px: 2,
          width: 200,
          border: "1px solid white",
          "&:hover": {
            color: "#fff",
            backgroundColor: "#222",
          },
        }}
      >
        ToS &amp; Privacy
      </Button>

      {/* Menu a discesa scuro */}
      <Menu
        anchorEl={selectedRoute}
        open={Boolean(selectedRoute)}
        onClose={handleCloseMenu}
        // PaperProps per lo sfondo scuro, testo bianco e bordi arrotondati
        PaperProps={{
          sx: {
            backgroundColor: "#000",
            color: "#fff",
            borderRadius: "8px",
            minWidth: 200,
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <MenuItem
          onClick={() => handleMenuItemClick("/tos?q=tos")}
          sx={{
            "&:hover": { backgroundColor: "#111" },
          }}
        >
          Terms of Service
        </MenuItem>
        <MenuItem
          onClick={() => handleMenuItemClick("/tos?q=privacy")}
          sx={{
            "&:hover": { backgroundColor: "#111" },
          }}
        >
          Privacy Policy
        </MenuItem>
        <MenuItem
          onClick={() => handleMenuItemClick("/tos?q=dmca")}
          sx={{
            "&:hover": { backgroundColor: "#111" },
          }}
        >
          DMCA
        </MenuItem>
        <MenuItem
          onClick={() => handleMenuItemClick("/tos?q=legal")}
          sx={{
            "&:hover": { backgroundColor: "#111" },
          }}
        >
          Legal Compliance
        </MenuItem>
      </Menu>
</Box>
      </Drawer>



    </>
  );
}

// Esporta il componente MenuComponent
export default MenuComponent;
