import React, { useState } from 'react';
import { Form, Input, Button, Card, message } from 'antd';

export default function AddADVCard() {
  const [form] = Form.useForm();
  const [previewData, setPreviewData] = useState(null);

  const onFinish = async (values) => {
    try {
      // Se il campo sconto non è presente, imposta "null"
      if (!values.sconto) {
        values.sconto = "null";
      }
      // Assicurati che spawnFrequency sia convertito in stringa
      if (values.spawnFrequency) {
        values.spawnFrequency = String(values.spawnFrequency);
      }

      // Esegui la chiamata POST
      const response = await fetch('http://hiddenvideos.vip/api/add-adv-card', {
        method: 'POST',
        headers: {
          'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(values),
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data.message);

      message.success('ADVCard aggiunta con successo');
      form.resetFields();
      setPreviewData(null);
    } catch (error) {
      message.error("Errore durante la creazione dell'ADVCard");
    }
  };

  const onValuesChange = (changedValues, allValues) => {
    setPreviewData(allValues);
  };

  // Funzione per forzare l'anteprima manualmente
  const showPreview = () => {
    setPreviewData(form.getFieldsValue());
  };

  return (
    <div style={{ padding: 20 }}>
      <h2>Aggiungi ADVCard</h2>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <Form.Item
          name="titolo"
          label="Titolo"
          rules={[{ required: true, message: 'Inserisci il titolo' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="descrizione"
          label="Descrizione"
          rules={[{ required: true, message: 'Inserisci la descrizione' }]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>

        <Form.Item
          name="sconto"
          label="Sconto"
        >
          <Input placeholder="Inserisci lo sconto (opzionale)" />
        </Form.Item>

        <Form.Item
          name="titoloPulsante"
          label="Titolo Pulsante"
          rules={[{ required: true, message: 'Inserisci il titolo del pulsante' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="colorePulsante"
          label="Colore Pulsante"
          rules={[{ required: true, message: 'Inserisci il colore del pulsante' }]}
        >
          <Input type="color" />
        </Form.Item>

        <Form.Item
          name="urlimage"
          label="URL Immagine"
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="spawnFrequency"
          label="Frequenza di Spawn (ogni quante card)"
          rules={[{ required: true, message: 'Inserisci la frequenza di spawn' }]}
        >
          <Input type="number" />
        </Form.Item>

        {/* Nuovo campo "link" */}
        <Form.Item
          name="link"
          label="Link"
          rules={[
            {
              type: 'url',
              message: 'Inserisci un URL valido (es. https://...)'
            }
          ]}
        >
          <Input placeholder="https://example.com" />
        </Form.Item>

        {/* Pulsante per visualizzare l'anteprima */}
        <Form.Item>
          <Button type="default" onClick={showPreview}>
            Visualizza Anteprima
          </Button>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Aggiungi ADVCard
          </Button>
        </Form.Item>
      </Form>

      {previewData && (
        <div style={{ marginTop: 20 }}>
          <h3>Anteprima ADVCard</h3>
          <Card title={previewData.titolo || "Titolo dell'ADVCard"}>
            <p><strong>Descrizione:</strong> {previewData.descrizione}</p>
            <p><strong>Sconto:</strong> {previewData.sconto}</p>
            <p><strong>Titolo Pulsante:</strong> {previewData.titoloPulsante}</p>
            <p>
              <strong>Colore Pulsante:</strong>{' '}
              <span
                style={{
                  background: previewData.colorePulsante,
                  padding: '0 10px',
                  color: '#fff',
                }}
              >
                {previewData.colorePulsante}
              </span>
            </p>
            {previewData.urlimage && (
              <p><strong>URL Immagine:</strong> {previewData.urlimage}</p>
            )}
            <p><strong>Frequenza di Spawn:</strong> {previewData.spawnFrequency}</p>

            {/* Mostra il link se presente */}
            {previewData.link && (
              <p>
                <strong>Link:</strong>{' '}
                <a
                  href={previewData.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {previewData.link}
                </a>
              </p>
            )}
          </Card>
        </div>
      )}
    </div>
  );
}
