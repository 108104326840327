import React from 'react';
import { Outlet, useNavigate, Link } from 'react-router-dom';
import { ProLayout } from '@ant-design/pro-components';
import { ConfigProvider, Button, theme } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import { AppstoreOutlined, PlusOutlined, TagsOutlined, LogoutOutlined, LinkOutlined, RedditCircleFilled } from '@ant-design/icons';

const menuItems = [
  { path: '/dashboard/cards', name: 'Cards', icon: <AppstoreOutlined /> },
  { path: '/dashboard/add-card', name: 'Aggiungi Card', icon: <PlusOutlined /> },
  { path: '/dashboard/categories', name: 'Categorie', icon: <TagsOutlined /> },
  { path: '/dashboard/add-category', name: 'Aggiungi Categoria', icon: <PlusOutlined /> },
  { path: '/dashboard/adv-cards', name: 'ADV Cards', icon: <AppstoreOutlined /> },
  { path: '/dashboard/add-adv-card', name: 'Aggiungi ADVCard', icon: <PlusOutlined /> },
  { path: '/dashboard/reddit', name: 'Reddit Link', icon: <RedditCircleFilled /> },
  { path: '/dashboard/linkvertise', name: 'Linkvertise ID', icon: <LinkOutlined /> },
  { path: '/dashboard/add-poster-telegram', name: 'Add Poster Telegram', icon: <LinkOutlined /> },
];

export default function AdminDashboard() {
  const navigate = useNavigate();

  const handleLogout = async () => {
    await fetch('https://hiddenvideos.vip/logout', {
      method: 'POST',
      headers: {
        'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });
    navigate('/');
  };

  return (
    <ConfigProvider locale={enUS} theme={{ algorithm: theme.darkAlgorithm }}>
      <ProLayout
        title="Admin Dashboard"
        navTheme="dark"
        headerTheme="dark"
        layout="side"
        route={{ routes: menuItems }}
        menuItemRender={(item, dom) => <Link to={item.path}>{dom}</Link>}
        // Aggiunge uno spazio dall'alto al menu laterale in modalità mobile
        drawerProps={{ style: { paddingTop: 64 } }}
        rightContentRender={() => (
          <Button
            type="primary"
            danger
            icon={<LogoutOutlined />}
            onClick={handleLogout}
            style={{ marginRight: 16 }}
          >
            Logout
          </Button>
        )}
      >
        <Outlet />
      </ProLayout>
    </ConfigProvider>
  );
}
