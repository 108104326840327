import React from 'react';
import { Box, Container, Typography, Link, IconButton, Grid } from '@mui/material';
import { Email as EmailIcon } from '@mui/icons-material';
import TelegramIcon from '@mui/icons-material/Telegram';
import { site, emailcontact } from './config';
import EmailLink from './email';

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        // Basta un background, padding, ecc.
        backgroundColor: '#000000',
        color: '#e0e0e0',
        py: 3,
      }}
    >
      <Container maxWidth="lg" sx={{ textAlign: 'center' }}>
       <Typography variant="body2" sx={{ mb: 2 }}>
        &copy; {new Date().getFullYear()}{" "}
        <Link href="/" color="inherit">
          {site}
        </Link>
        . All rights reserved.
      </Typography>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm="auto">
            <Link
  href={`mailto:${emailcontact}`}
  color="inherit"
  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
>
  <EmailIcon sx={{ mr: 1 }} />
  {emailcontact}
</Link>

          </Grid>
          <Grid item xs={12} sm="auto" sx={{ mt: 1 }}>
            <Typography variant="body2">
              <Link
                href="/tos"
                color="inherit"
                sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
              >
                TOS &amp; Privacy Policy
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
