import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Link, Container, Grid, Button, Card, Box, Typography, useMediaQuery } from '@mui/material'; // Aggiungi qui i componenti mancanti
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import UnlockContent from './unlockcontent';
import '../App.css';
import CryptoJS from 'crypto-js';
import CategoryIcon from "@mui/icons-material/Category";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CardCustom from "./card";
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';


import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';


function timeAgo(dateString) {
  const date = new Date(dateString);
  const now = new Date();
  const seconds = Math.floor((now - date) / 1000);



  

  const intervals = {
    year: 31536000,
    month: 2592000,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1,
  };

  for (const [unit, value] of Object.entries(intervals)) {
    const interval = Math.floor(seconds / value);
    if (interval >= 1) {
      return `${interval} ${unit}${interval > 1 ? 's' : ''} ago`;
    }
  }
  return 'just now';
}


function md5ToBase64(url) {
  // Step 1: Convert the URL to an MD5 hash
  const md5Hash = CryptoJS.MD5(url).toString();
  
  // Step 2: Create an array with the hash and the additional value
  const array = [md5Hash, "22dcbe26d606977f9484257ea466af9254979b97"];
  
  // Step 3: Convert the array to a JSON string
  const jsonString = JSON.stringify(array);
  
  // Step 4: Encode the JSON string to base64
  const base64String = btoa(jsonString);
  
  return base64String;
}

  

const CardStep = () => {
  const { id } = useParams();
  const [card, setCard] = useState(null);
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);


const [openTutorial, setOpenTutorial] = useState(false); // Stato per il video tutorial

  const handleOpenTutorial = () => {
    setOpenTutorial(true);
  };

  const handleCloseTutorial = () => {
    setOpenTutorial(false);
  };


   const [data, setData] = useState([]);
   const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchCard = async () => {
      try {
        const response = await fetch(`https://hiddenvideos.vip/api/card/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
          'Content-Type': 'application/json'
        }
      });
        if (!response.ok) {
          throw new Error('Card not found');
          window.location.href = "/"
        }
        const data = await response.json();
        setCard(data);
      } catch (error) {
        window.location.href = "/"
      }
    };
    fetchCard();
  }, [id]);

  const title = card?.title || 'N/A';
  const time = card?.time || '';
  const category = card?.category || '';

  const imageUrl = card?.imageUrl || '';
  const link = card?.link || '';
  const type = card?.type || '';

  const timetogo = timeAgo(time);


      const handleClick = () => {
      let param = md5ToBase64(`https://hiddenvideos.vip/card/${id}`)
      navigator.clipboard.writeText(`https://hiddenvideos.vip/card/${id}?u=${param}`).then(() => {
        setOpen(true);
      });
    };
    const handleClose = () => {
      setOpen(false);
    };



   const handleClickOpen = async () => {
    setLoading(true);
    try {

      const response = await fetch(`https://hiddenvideos.vip/api/more?q=${title}`, {
        method: 'GET',
        headers: {
          'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      setData(result);
      setOpenDrawer(true);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleCardClick = (id) => {
    navigate(`/category/${id}`); // Naviga alla rotta /category/:id
  };
  const openlink = (id) => {
    let param = md5ToBase64(`https://hiddenvideos.vip/card/${id}`)
    navigate(`/card/${id}?u=${param}`); // Naviga alla rotta /category/:id
    setOpenDrawer(false);
  };
  const [categories, setCategories] = useState([]);
  const [categoryName, setCategoryName] = useState(category);
  useEffect(() => {
    const fetchCategories = async () => {
      try {


      const response = await fetch(`https://hiddenvideos.vip/api/categories`, {
        method: 'GET',
        headers: {
          'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
          'Content-Type': 'application/json'
        }
      });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        setCategories(result);
      } catch (error) {
      }
    };
    fetchCategories();
  }, []);

  const getCategoryName = (cats) =>{
    if (categories.length > 0) {
      const categoryObj = categories.find(cat => cat.id === cats);

      if (categoryObj) {
        return categoryObj.name
      }
      return "Uncategory"
    }
  }
  // Update category name based on category ID
  useEffect(() => {
    if (categories.length > 0) {
      const categoryObj = categories.find(cat => cat.id === category);
      if (categoryObj) {
        setCategoryName(categoryObj.name);
      }
    }
  }, [categories, category]);
const [gotoFunction, setGotoFunction] = useState(() => () => {});
const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Container container spacing={2} justifyContent="center">
   <Box
  sx={{
    display: "flex",
    flexDirection: "column",  // Disposizione verticale
    justifyContent: "center",
    alignItems: "center",
    minHeight: "200px",
    p: 2,
  }}
>
    <Card
      sx={{
        width: 400,
        height: 700,
        borderRadius: 3,
        overflow: "hidden",
        boxShadow: "0 4px 12px rgba(0,0,0,0.3)",
        backgroundColor: "#2c2c2c",
        position: "relative",
      }}
    >
      {/* Sfondo blurata */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          filter: "blur(8px)",
          zIndex: 1,
          opacity: 0.7,
        }}
      />

      {/* Header con titolo e info */}
      <Box
        sx={{
          backgroundColor: "#000",
          color: "#fff",
          p: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          position: "relative",
          zIndex: 2,
          width: "19rem",
          mt: "1rem",
         ml: {
            xs: "1.5rem",
            sm: "1.5rem",
            md: "1.5rem",
          },
          borderRadius: "8px",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 600, mb: 1 }}>
          {title}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Button
            onClick={() => handleCardClick(category)}
            sx={{
              color: "#ccc",
              textTransform: "none",
              fontSize: "16px",
              py: 0,
              px: 1,
            }}
          >
            <CategoryIcon sx={{ mr: 0.5 }} /> {getCategoryName(category)}
          </Button>
          <Button
            sx={{
              color: "#ccc",
              textTransform: "none",
              fontSize: "16px",
              py: 0,
              px: 1,
            }}
          >
            <AccessTimeIcon sx={{ mr: 0.5 }} /> {timetogo}
          </Button>
        </Box>
      </Box>

      {/* Immagine principale */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          p: 3,
          position: "relative",
          zIndex: 2,
        }}
      >
        {imageUrl && (
          <img
            src={imageUrl}
            alt={title}
            style={{
              width: "100%",
              height: "400px",
              objectFit: "cover",
              borderRadius: "8px",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.3)",
            }}
          />
        )}
      </Box>

      {/* Barra dei pulsanti in basso */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          p: 1,
          zIndex: 2,
          mt: -1,
          borderRadius: 3,
          height: "100px",
        }}
      >
        {/* Righe: prima riga contiene UnlockContent */}
        <Box sx={{ display: "flex", justifyContent: "center",zIndex: 99 }}>
          <UnlockContent id={id} finalLink={link} type={type} />
        </Box>
        {/* Seconda riga con pulsante per il Tutorial e More */}
        <Box sx={{ display: "flex", justifyContent: "space-between",zIndex: 999 }}>
          <Button
            onClick={handleOpenTutorial}
            variant="contained"
            sx={{
              backgroundColor: "rgb(13,13,13)",
              color: "#fff",
              textTransform: "none",
              fontWeight: 500,
              fontSize: "16px",
              borderRadius: "8px",
              px: 6,
              py: 1,
              "&:hover": {
                backgroundColor: "#1e1e1e",
              },

            }}
          >
          <OndemandVideoIcon sx={{ mr: 1 }} />
            Tutorial
          </Button>

          <Button
            onClick={handleClickOpen}
            variant="contained"
            sx={{
              backgroundColor: "rgb(13,13,13)",
              color: "#fff",
              textTransform: "none",
              fontWeight: 500,
              fontSize: "16px",
              borderRadius: "8px",
              px: 6,
              py: 1,
              "&:hover": {
                backgroundColor: "#1e1e1e",
              },
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              style={{ marginRight: "0.3rem" }}
            >
              <path d="M19.07 4.93A10 10 0 0 0 6.99 3.34"></path>
              <path d="M4 6h.01"></path>
              <path d="M2.29 9.62A10 10 0 1 0 21.31 8.35"></path>
              <path d="M16.24 7.76A6 6 0 1 0 8.23 16.67"></path>
              <path d="M12 18h.01"></path>
              <path d="M17.99 11.66A6 6 0 0 1 15.77 16.67"></path>
              <circle cx="12" cy="12" r="2"></circle>
              <path d="m13.41 10.59 5.66-5.66"></path>
            </svg>
            More
          </Button>
        </Box>
      </Box>

      {/* Snackbar per notifiche */}
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Link copied to clipboard!
        </Alert>
      </Snackbar>


<Drawer
  anchor="bottom"
  open={openDrawer}
  onClose={handleCloseDrawer}
  sx={{
    "& .MuiDrawer-paper": {
      background: "rgba(0, 0, 0, 0.85)",
      backdropFilter: "blur(12px)",
      color: "#ffffff",
      overflowX: "hidden",
      overflowY: "visible",
      minHeight: 400,
      maxHeight: "100%",
      borderTopLeftRadius: "30px",
      borderTopRightRadius: "30px",
      boxShadow: "0px -6px 30px rgba(0, 0, 0, 0.8)",
      // Nascondi la scrollbar
      scrollbarWidth: "none",
      "-ms-overflow-style": "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  }}
  PaperProps={{
    sx: {
      height: { xs: "80%", md: "85%" }, // Altezza diversa per mobile e desktop
      display: "flex",
      flexDirection: "column",
      backgroundColor: "transparent",
    },
  }}
  ModalProps={{ keepMounted: true }}
>
  {/* Handle per indicare lo swipe */}
  <Box sx={{ display: "flex", justifyContent: "center", py: 1 }}>
    <Box
      sx={{
        width: "50px",
        height: "5px",
        borderRadius: "3px",
        background:
          "linear-gradient(90deg, rgba(255,255,255,0.9), rgba(255,255,255,0.5))",
      }}
    />
  </Box>

  {/* Header con titolo e pulsante per chiudere */}
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      px: 4,
      py: 2,
      borderBottom: "1px solid rgba(255,255,255,0.2)",
      mb: 1,
    }}
  >
    <Typography
      variant="h6"
      sx={{
        fontWeight: 700,
        letterSpacing: 1.2,
        textTransform: "uppercase",
      }}
    >
      You might also like...
    </Typography>
    <IconButton onClick={handleCloseDrawer} sx={{ color: "#FF4081" }}>
      <CloseIcon fontSize="large" />
    </IconButton>
  </Box>

  {/* Contenuto principale con spazio extra per le card */}
  <Box sx={{ flexGrow: 1, px: 4, py: 3, minHeight: 500, overflow: "visible" }}>
    {loading ? (
      <Typography variant="body1">Loading...</Typography>
    ) : data && data.length > 0 ? (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          overflowX: "auto",
          alignItems: "center",
          py: 2,
          // Nascondi le scrollbar orizzontali
          scrollbarWidth: "none",
          "-ms-overflow-style": "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {data.map((item, index) => (
          <Box
            key={item.id}
            sx={{
              flex: "0 0 auto",
              width: { xs: "80%", sm: 320 },
              height: {xs: "100%", md: "30%"},
              ml: index === 0 ? 0 : { xs: "-30px", sm: "60px", md: "90px" },
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
              cursor: "pointer",
              "&:hover": {
                transform: "scale(1.1)",
                zIndex: 3,
                boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.4)",
              },
            }}
          >
            <CardCustom
              id={item.id}
              imageUrl={item.imageUrl}
              title={item.title}
              category={item.category}
              time={item.time}
            />
          </Box>
        ))}
      </Box>
    ) : (
      <Typography variant="body1">No results found.</Typography>
    )}
  </Box>
</Drawer>


      {/* Dialog per il tutorial */}
      <Dialog
        open={openTutorial}
        onClose={handleCloseTutorial}
        fullWidth
        maxWidth="md"
         PaperProps={{
            sx: {
              background: "linear-gradient(135deg, #000, #1a1a1a)",
              color: "#fff",
              borderRadius: "12px",
              p: 3,
              boxShadow: "0px 8px 24px rgba(0,0,0,0.5)",
            },
          }}
      >
        <DialogTitle
          sx={{
      textAlign: "center",
      fontSize: "1.75rem",
      fontWeight: "bold",
      pb: 2,
      position: "relative",
    }}
        >
          Tutorial
          <IconButton
            aria-label="close"
            onClick={handleCloseTutorial}
             sx={{
        position: "absolute",
        right: 8,
        top: 8,
        color: "#fff",
      }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
           sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: 2,
      p: 0,}}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "400px",
              overflow: "hidden",
            }}
          >
            <video
              width="100%"
              height="100%"
              controls
              style={{
                borderRadius: "8px",
                border: "1px solid #fff",
              }}
            >
              <source src="/video.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Box>
        </DialogContent>
      </Dialog>
    </Card>
    </Box>
    </Container>
  );
};

export default CardStep;



