
import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, message } from 'antd';

export default function Categories() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingCategory, setEditingCategory] = useState(null);
  const [form] = Form.useForm();

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://hiddenvideos.vip/api/categories', {
        method: 'GET',
        headers: {
          'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      });
      const data = await response.json();
      setCategories(data);
    } catch (error) {
      message.error('Errore nel recupero delle categorie');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleEdit = (record) => {
    setEditingCategory(record);
    form.setFieldsValue(record);
  };

  const handleDelete = async (id) => {
    // Supponendo che esista la rotta DELETE per le categorie
    try {
      await fetch(`https://hiddenvideos.vip/api/categories/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      });
      message.success('Categoria eliminata');
      fetchCategories();
    } catch (error) {
      message.error('Errore durante l\'eliminazione');
    }
  };

  const handleUpdate = async () => {
    try {
      const values = await form.validateFields();
      await fetch(`https://hiddenvideos.vip/api/categories/${editingCategory.id}`, {
        method: 'PUT',
        headers: {
          'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(values)
      });
      message.success('Categoria aggiornata');
      setEditingCategory(null);
      fetchCategories();
    } catch (error) {
      message.error('Errore durante l\'aggiornamento');
    }
  };

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'Nome', dataIndex: 'name', key: 'name' },
    { title: 'URL Immagine', dataIndex: 'urlimg', key: 'urlimg' },
    {
      title: 'Azioni',
      key: 'actions',
      render: (_, record) => (
        <>
          <Button onClick={() => handleEdit(record)}>Modifica</Button>
          <Button danger onClick={() => handleDelete(record.id)} style={{ marginLeft: 8 }}>
            Elimina
          </Button>
        </>
      )
    }
  ];

  return (
    <div style={{ padding: 20 }}>
      <h2>Gestione Categorie</h2>
      <Table dataSource={categories} columns={columns} rowKey="id" loading={loading} />
      <Modal
        title="Modifica Categoria"
        visible={!!editingCategory}
        onOk={handleUpdate}
        onCancel={() => setEditingCategory(null)}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label="Nome"
            rules={[{ required: true, message: 'Inserisci il nome della categoria' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="urlimg"
            label="URL Immagine"
            rules={[{ required: true, message: 'Inserisci l\'URL dell\'immagine' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
