import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container } from '@mui/material';
import { useEffect } from 'react';


import CardStep from './component/cardstep';
import TermsOfService from './component/tos';
import Main from "./component/main";
import MenuComponent from './component/menu';
import Footer from './component/footer';
import Category from './component/catlist';
import CategoryDetail from './component/catid';
import Htgc from './component/htgc';
import UniqueCardsPage from './component/model';
import AdminDashboard from './admin/dashboard';
import Login from './admin/login';
import Cards from './admin/card';
import AddCard from './admin/addcard';
import AddADVCard from './admin/addADVCard';
import ADVCards from './admin/ADVCards';
import Categories from './admin/categories';
import AddCategory from './admin/addcategory';
import RedditManager from './admin/RedditManager';
import LinkvertiseManager from './admin/LinkvertiseManager';
import PosterManagement from './admin/addPoster';

import PosterForm from './poster/poster';
import PrivateRoute from './poster/PrivateTelegram';

import LayoutDefault from "./layout"; // include MenuComponent, Container, Footer
import LayoutEmpty from "./nolayout"; // layout vuoto, senza menu e footer

function App() {
  useEffect(() => {
    // Carica dinamicamente Google Analytics
    const script1 = document.createElement("script");
    script1.async = true;
    script1.src = "https://www.googletagmanager.com/gtag/js?id=G-27YNFPK270";
    document.head.appendChild(script1);

    // Inizializza Google Analytics
    const script2 = document.createElement("script");
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-27YNFPK270');
    `;
    document.head.appendChild(script2);
  }, []);

  return (
   <Router>
      <Routes>
        {/* Layout vuoto per /poster */}
        <Route element={<LayoutEmpty />}>
          <Route
      path="/poster"
      element={
          <PosterForm />
      }
    />
          <Route path="/dashboard" element={<AdminDashboard />}>
            <Route path="cards" element={<Cards />} />
            <Route path="add-card" element={<AddCard />} />
            <Route path="categories" element={<Categories />} />
            <Route path="add-category" element={<AddCategory />} />
            <Route path="add-adv-card" element={<AddADVCard />} />
            <Route path="adv-cards" element={<ADVCards />} />
            <Route path="reddit" element={<RedditManager />} />
            <Route path="linkvertise" element={<LinkvertiseManager />} />
            <Route path="add-poster-telegram" element={<PosterManagement />} />
          </Route>
          <Route path="/admin/login" element={<Login />} />
        </Route>
        {/* Layout completo per tutte le altre pagine */}
        <Route element={<LayoutDefault />}>
          <Route path="/" element={<Main />} />
          <Route path="/card/:id/:hash?" element={<CardStep />} />
          <Route path="/tos" element={<TermsOfService />} />
          <Route path="/category" element={<Category />} />
          <Route path="/category/:id" element={<CategoryDetail />} />
          <Route path="/how-to-get-coins" element={<Htgc />} />
          <Route path="/models" element={<UniqueCardsPage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
