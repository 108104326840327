import React, { useState, useEffect } from "react";
import { Box, Grid, CircularProgress, Typography } from "@mui/material";
import CardCustom from "./card";         // La tua card normale
import AdvCardCustom from "./adv";        // La tua card ADV personalizzata

export default function CardsGrid({ displayedCards = [], loading }) {
  const [advCards, setAdvCards] = useState([]);
  const [advLoading, setAdvLoading] = useState(false);

  // Effettua la richiesta per ottenere le ADVCard
  useEffect(() => {
    async function fetchAdvCards() {
      setAdvLoading(true);
      try {
        const response = await fetch("http://localhost:3001/api/adv-cards", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "=1l<*uq8Ks8Fhxg5-]b",
          },
          credentials: "include",
        });
        const data = await response.json();
        setAdvCards(data);
      } catch (error) {
        console.error("Errore nel recupero delle ADVCard:", error);
      }
      setAdvLoading(false);
    }
    fetchAdvCards();
  }, []);

  // Se il caricamento è in corso, mostra lo spinner
  if (loading || advLoading) {
   return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "200px",
        p: 2,
        position: "relative",
      }}
    >
      {/* Spinner di caricamento in rosso e ingrandito */}
      <CircularProgress size={100} thickness={4} sx={{ color: "red" }} />
      {/* Icona (logo) centrata */}
      <Box
        component="img"
        src="/favicon.jpeg" // Sostituisci con il percorso del tuo logo
        alt="Logo"
        sx={{
          position: "absolute",
          width: { xs: "60px", sm: "80px", md: "100px" },
        }}
      />
    </Box>
  );
}


  // Se non ci sono card normali e nemmeno ADVCard, mostra un messaggio
  if (displayedCards.length === 0 && advCards.length === 0) {
    return (
     <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "200px",
        p: 2,
        position: "relative",
      }}
    >
      {/* Spinner di caricamento in rosso e ingrandito */}
      <CircularProgress size={100} thickness={4} sx={{ color: "red" }} />
      {/* Icona (logo) centrata */}
      <Box
        component="img"
        src="/favicon.jpeg" // Sostituisci con il percorso del tuo logo
        alt="Logo"
        sx={{
          position: "absolute",
          width: { xs: "60px", sm: "80px", md: "100px" },
        }}
      />
    </Box>
    );
  }

  // Combina le card normali e le ADVCard
  let combinedCards = [];
  if (displayedCards.length > 0) {
    for (let i = 0; i < displayedCards.length; i++) {
      // Aggiungi la card normale
      combinedCards.push({ ...displayedCards[i], type: "regular" });

      // Cerca le ADVCard eleggibili in base al parametro spawnFrequency
      // Aggiungiamo il controllo: se adv.hide è true (o "true"), la ADVCard non viene inserita
      const eligibleAdvCards = advCards.filter((adv) => {
        const freq = Number(adv.spawnFrequency);
        const isVisible = !(adv.hide === 'true' || adv.hide === true);
        return isVisible && freq && ((i + 1) % freq === 0);
      });

      if (eligibleAdvCards.length > 0) {
        // Se più ADVCard sono eleggibili, se ne sceglie una a caso
        const randomIndex = Math.floor(Math.random() * eligibleAdvCards.length);
        const advToInsert = eligibleAdvCards[randomIndex];
        combinedCards.push({ ...advToInsert, type: "adv" });
      }
    }
  } else {
    // Se non ci sono card normali, usa solo le ADVCard (escludendo quelle nascoste)
    combinedCards = advCards
      .filter((adv) => !(adv.hide === 'true' || adv.hide === true))
      .map((adv) => ({ ...adv, type: "adv" }));
  }

  return (
    <Box sx={{ mt: 3, mx: "auto", px: { xs: 2, sm: 4, md: 2 } }}>
      <Grid container rowSpacing={3} columnSpacing={2} justifyContent="center">
        {combinedCards.map((card, idx) => (
          <Grid
            item
            key={`${card.id}-${card.type}-${idx}`}
            xs={12}  // 1 carta per riga: 12/12
            sm={6}   // 2 carte per riga: 6/12
            md={4}   // 3 carte per riga: 4/12
            lg={3}   // 4 carte per riga: 3/12
            xl={2}
          >
            {card.type === "adv" ? (
              <AdvCardCustom
                id={card.id}
                titolo={card.titolo}
                descrizione={card.descrizione}
                sconto={card.sconto}
                titoloPulsante={card.titoloPulsante}
                colorePulsante={card.colorePulsante}
                urlimage={card.urlimage}
                hide={card.hide}
                spawnFrequency={card.spawnFrequency}
                time={card.time}
                link={card.link}
                // Passa gli effetti ricevuti (o usa "default" se non specificati)
                titleEffectName={card.titleEffect || "default"}
                buttonEffectName={card.buttonEffect || "default"}
              />
            ) : (
              <CardCustom
                title={card.title || card.titolo}
                category={card.category || card.categoria || ""}
                time={card.time}
                imageUrl={card.imageUrl || card.urlimage}
                id={card.id}
                isAdv={false}
              />
            )}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
