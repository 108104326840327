import React, { useState } from 'react';
import { Card, Form, Input, Button, message } from 'antd';

export default function LinkvertiseManager() {
  const [linkvertiseId, setLinkvertiseId] = useState('');

  const loadLinkvertiseId = async () => {
    try {
      const response = await fetch('https://hiddenvideos.vip/api/linkvertise'); // GET
      if (!response.ok) throw new Error('Errore nel recupero dell’ID di Linkvertise');
      const data = await response.json();
      if (data.linkvertiseId) {
        setLinkvertiseId(data.linkvertiseId);
        message.success('ID di Linkvertise caricato');
      } else {
        message.warning('Nessun ID di Linkvertise salvato');
      }
    } catch (error) {
      console.error(error);
      message.error('Impossibile caricare l’ID di Linkvertise');
    }
  };

  const saveLinkvertiseId = async () => {
    try {
      const response = await fetch('https://hiddenvideos.vip/api/linkvertise', {
        method: 'POST',
        headers: {
          'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id: linkvertiseId }),
      });
      const result = await response.json();
      if (!response.ok) throw new Error(result.message || 'Errore generico');
      message.success('ID di Linkvertise salvato con successo');
    } catch (error) {
      console.error(error);
      message.error('Impossibile salvare l’ID di Linkvertise');
    }
  };

  return (
    <Card title="Gestione Linkvertise ID" style={{ margin: '16px' }}>
      <Form layout="vertical">
        <Form.Item label="Linkvertise ID">
          <Input
            placeholder="Esempio: 12345"
            value={linkvertiseId}
            onChange={(e) => setLinkvertiseId(e.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={saveLinkvertiseId} style={{ marginRight: 8 }}>
            Salva
          </Button>
          <Button onClick={loadLinkvertiseId}>Carica</Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
