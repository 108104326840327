import React, { useState } from 'react';
import { Card, Form, Input, Button, message } from 'antd';

export default function RedditManager() {
  const [redditLink, setRedditLink] = useState('');

  const loadRedditLink = async () => {
    try {
      const response = await fetch('https://hiddenvideos.vip/api/reddit'); // GET
      if (!response.ok) throw new Error('Errore nel recupero del link Reddit');
      const data = await response.json();
      if (data.redditLink) {
        setRedditLink(data.redditLink);
        message.success('Link di Reddit caricato');
      } else {
        message.warning('Nessun link di Reddit salvato');
      }
    } catch (error) {
      console.error(error);
      message.error('Impossibile caricare il link di Reddit');
    }
  };

  const saveRedditLink = async () => {
    try {
      const response = await fetch('https://hiddenvideos.vip/api/reddit', {
        method: 'POST',
        headers: {
         'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ link: redditLink }),
      });
      const result = await response.json();
      if (!response.ok) throw new Error(result.message || 'Errore generico');
      message.success('Link di Reddit salvato con successo');
    } catch (error) {
      console.error(error);
      message.error('Impossibile salvare il link di Reddit');
    }
  };

  return (
    <Card title="Gestione Reddit Link" style={{ margin: '16px' }}>
      <Form layout="vertical">
        <Form.Item label="Reddit Link">
          <Input
            placeholder="https://www.reddit.com/..."
            value={redditLink}
            onChange={(e) => setRedditLink(e.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={saveRedditLink} style={{ marginRight: 8 }}>
            Salva
          </Button>
          <Button onClick={loadRedditLink}>Carica</Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
