import React, { useState, useEffect } from 'react';
import './css/Card.css';
import {
  Card,
  Typography,
  Button,
  Box,
  Grid,
  Link,
  useMediaQuery,
} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import CryptoJS from 'crypto-js';

import CategoryIcon from "@mui/icons-material/Category";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

// Funzione per il tempo relativo
function timeAgo(dateString) {
  const date = new Date(dateString);
  const now = new Date();
  const seconds = Math.floor((now - date) / 1000);

  const intervals = {
    year: 31536000,
    month: 2592000,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1,
  };

  for (const [unit, value] of Object.entries(intervals)) {
    const interval = Math.floor(seconds / value);
    if (interval >= 1) {
      return `${interval} ${unit}${interval > 1 ? 's' : ''} ago`;
    }
  }
  return 'just now';
}

// Funzione per convertire MD5 in Base64
function md5ToBase64(url) {
  const md5Hash = CryptoJS.MD5(url).toString();
  const array = [md5Hash, "22dcbe26d606977f9484257ea466af9254979b97"];
  const jsonString = JSON.stringify(array);
  const base64String = btoa(jsonString);
  return base64String;
}

const CardCustom = ({ title, category, time, imageUrl, id }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');

  const [open, setOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoryName, setCategoryName] = useState(category);

  const timetogo = timeAgo(time);

  // Copia link negli appunti
  const handleClick = () => {
    const param = md5ToBase64(`https://hiddenvideos.vip/card/${id}`);
    navigator.clipboard.writeText(`https://hiddenvideos.vip/card/${id}?u=${param}`).then(() => {
      setOpen(true);
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Funzione per fetch dei dati "More"
  const handleClickOpen = async () => {
    setLoading(true);
    try {
      const response = await fetch(`https://hiddenvideos.vip/api/more?q=${title}`, {
        method: 'GET',
        headers: {
          'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      setData(result);
      setOpenDrawer(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleCardClick = (id) => {
    navigate(`/category/${id}`);
  };

  const openlink = (id) => {
    const param = md5ToBase64(`https://hiddenvideos.vip/card/${id}`);
    navigate(`/card/${id}?u=${param}`);
  };

  // Fetch delle categorie
  const fetchCategories = async () => {
    try {
      const response = await fetch(`https://hiddenvideos.vip/api/categories`, {
        method: 'GET',
        headers: {
          'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      setCategories(result);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  // Recupera il nome della categoria
  const getCategoryName = (catId) => {
    const categoryObj = categories.find(cat => cat.id === catId);
    return categoryObj ? categoryObj.name : "Uncategorized";
  };

  // Aggiorna il nome della categoria una volta ricevute le categorie
  useEffect(() => {
    if (categories.length > 0) {
      const categoryObj = categories.find(cat => cat.id === category);
      if (categoryObj) {
        setCategoryName(categoryObj.name);
      }
    }
  }, [categories, category]);

  return (
    <Card
  sx={{
    width: { xs: '90%', sm: 400 },
    height: { xs: 'auto', sm: 670 },
    borderRadius: 3,
    overflow: "hidden",
    boxShadow: "0 4px 12px rgba(0,0,0,0.3)",
    backgroundColor: "#2c2c2c",
    position: "relative",
    margin: 'auto',
  }}
>
  {/* Sfondo blurato */}
  <Box
    sx={{
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage: `url(${imageUrl})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      filter: "blur(8px)",
      zIndex: 1,
      opacity: 0.7,
    }}
    /* Box autoconcluso */
  />

  {/* Header con titolo e info */}
  <Box
    sx={{
      backgroundColor: 'rgba(0,0,0,0.8)',
      color: '#fff',
      p: 3,
      position: "relative",
      zIndex: 2,
      mx: 2,
      mt: 2,
      borderRadius: "8px",
    }}
  >
    <Typography
      variant="h5"
      sx={{ fontWeight: 600, mb: 1, fontSize: { xs: '1rem', sm: '1.2rem', md: '1.5rem' } }}
    >
      {title}
    </Typography>
    <Box sx={{ display: "flex", alignItems: "center", gap: 2, flexWrap: 'wrap' }}>
      <Button
        onClick={() => handleCardClick(category)}
        sx={{
          color: "#ccc",
          textTransform: "none",
          fontSize: { xs: '14px', sm: '16px' },
          py: 0,
          px: 1,
        }}
      >
        <CategoryIcon sx={{ mr: 0.5 }} /> {getCategoryName(category)}
      </Button>
      <Button
        sx={{
          color: "#ccc",
          textTransform: "none",
          fontSize: { xs: '14px', sm: '16px' },
          py: 0,
          px: 1,
        }}
      >
        <AccessTimeIcon sx={{ mr: 0.5 }} /> {timetogo}
      </Button>
    </Box>
  </Box>

  {/* Wrapper generale */}
  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    {/* Immagine principale */}
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        p: 3,
        position: "relative",
        zIndex: 2,
      }}
    >
      {imageUrl && (
        <img
          src={imageUrl}
          alt={title}
          style={{
            width: "100%",
            height: isMobile ? "200px" : "350px",
            objectFit: "cover",
            borderRadius: "8px",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.3)",
          }}
        />
      )}
    </Box>

    {/* Pulsanti */}
    <Box
      sx={{
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    flexWrap: "nowrap", // forza i pulsanti sulla stessa riga
    gap: 2,
    p: 2,
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2
  }}
    >
      <Link
        component={RouterLink}
        to={`https://hiddenvideos.vip/card/${id}`}
        style={{ textDecoration: "none" }}
      >
        <Button
          onClick={() => openlink(id)}
          variant="contained"
          sx={{
             backgroundColor: "rgb(13,13,13)",
              color: "#fff",
              textTransform: "none",
              fontWeight: 500,
              fontSize: "16px",
              borderRadius: "8px",
              px: 2.5,
              py: 1,
              "&:hover": {
                backgroundColor: "#1e1e1e",
              },
          }}
        >
          {/* Icona Open Link */}
          <svg
            width="20"
            height="20"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1000 1000"
            style={{ marginRight: "0.3rem" }}
          >
            <path
              fill="currentColor"
              d="M512 64C264.571 64 64 264.571 64 512s200.571 448 448 448 448-200.571 448-448S759.429 64 512 64zm232.553 606.424a13.861 13.861 0 01-13.883 13.884h-58.51a13.861 13.861 0 01-13.884-13.884V490.183a1.77 1.77 0 00-2.976-1.24L531.338 612.905a27.684 27.684 0 01-38.924 0L368.452 488.943a1.797 1.797 0 00-2.976 1.24v180.24a13.861 13.861 0 01-13.883 13.885h-58.51a13.861 13.861 0 01-13.884-13.884V353.576a13.861 13.861 0 0113.883-13.884h40.164a28.013 28.013 0 0119.586 8.182l154.21 154.209a6.736 6.736 0 009.669 0l154.209-154.21a27.577 27.577 0 0119.586-8.18h40.164a13.861 13.861 0 0113.883 13.883z"
            ></path>
          </svg>
          Open Link
        </Button>
      </Link>

      <Button
        onClick={handleClickOpen}
        variant="contained"
        sx={{
          backgroundColor: "rgb(13,13,13)",
              color: "#fff",
              textTransform: "none",
              fontWeight: 500,
              fontSize: "16px",
              borderRadius: "8px",
              px: 2.3,
              py: 1,
              "&:hover": {
                backgroundColor: "#1e1e1e",
              },
        }}
      >
        {/* Icona More */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          style={{ marginRight: "0.3rem" }}
        >
          <path d="M19.07 4.93A10 10 0 0 0 6.99 3.34"></path>
          <path d="M4 6h.01"></path>
          <path d="M2.29 9.62A10 10 0 1 0 21.31 8.35"></path>
          <path d="M16.24 7.76A6 6 0 1 0 8.23 16.67"></path>
          <path d="M12 18h.01"></path>
          <path d="M17.99 11.66A6 6 0 0 1 15.77 16.67"></path>
          <circle cx="12" cy="12" r="2"></circle>
          <path d="m13.41 10.59 5.66-5.66"></path>
        </svg>
        More
      </Button>
    </Box>
  </Box>
  {/* CHIUSURA del wrapper generale */}

  {/* Snackbar */}
  <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
    <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
      Link copied to clipboard!
    </Alert>
  </Snackbar>

  {/* Drawer */}
  <Drawer
  anchor="bottom"
  open={openDrawer}
  onClose={handleCloseDrawer}
  sx={{
    "& .MuiDrawer-paper": {
      background: "rgba(0, 0, 0, 0.85)",
      backdropFilter: "blur(12px)",
      color: "#ffffff",
      overflowX: "hidden",
      overflowY: "visible",
      minHeight: 400,
      maxHeight: "100%",
      borderTopLeftRadius: "30px",
      borderTopRightRadius: "30px",
      boxShadow: "0px -6px 30px rgba(0, 0, 0, 0.8)",
      // Nascondi la scrollbar
      scrollbarWidth: "none",
      "-ms-overflow-style": "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  }}
  PaperProps={{
    sx: {
      height: { xs: "80%", md: "85%" }, // Altezza diversa per mobile e desktop
      display: "flex",
      flexDirection: "column",
      backgroundColor: "transparent",
    },
  }}
  ModalProps={{ keepMounted: true }}
>
  {/* Handle per indicare lo swipe */}
  <Box sx={{ display: "flex", justifyContent: "center", py: 1 }}>
    <Box
      sx={{
        width: "50px",
        height: "5px",
        borderRadius: "3px",
        background:
          "linear-gradient(90deg, rgba(255,255,255,0.9), rgba(255,255,255,0.5))",
      }}
    />
  </Box>

  {/* Header con titolo e pulsante per chiudere */}
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      px: 4,
      py: 2,
      borderBottom: "1px solid rgba(255,255,255,0.2)",
      mb: 1,
    }}
  >
    <Typography
      variant="h6"
      sx={{
        fontWeight: 700,
        letterSpacing: 1.2,
        textTransform: "uppercase",
      }}
    >
      You might also like...
    </Typography>
    <IconButton onClick={handleCloseDrawer} sx={{ color: "#FF4081" }}>
      <CloseIcon fontSize="large" />
    </IconButton>
  </Box>

  {/* Contenuto principale con spazio extra per le card */}
  <Box sx={{ flexGrow: 1, px: 4, py: 3, minHeight: 500, overflow: "visible" }}>
    {loading ? (
      <Typography variant="body1">Loading...</Typography>
    ) : data && data.length > 0 ? (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          overflowX: "auto",
          alignItems: "center",
          py: 2,
          // Nascondi le scrollbar orizzontali
          scrollbarWidth: "none",
          "-ms-overflow-style": "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {data.map((item, index) => (
          <Box
            key={item.id}
            sx={{
              flex: "0 0 auto",
              width: { xs: "80%", sm: 320 },
              height: {xs: "100%", md: "30%"},
              ml: index === 0 ? 0 : { xs: "-30px", sm: "60px", md: "90px" },
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
              cursor: "pointer",
              "&:hover": {
                transform: "scale(1.1)",
                zIndex: 3,
                boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.4)",
              },
            }}
          >
            <CardCustom
              id={item.id}
              imageUrl={item.imageUrl}
              title={item.title}
              category={item.category}
              time={item.time}
            />
          </Box>
        ))}
      </Box>
    ) : (
      <Typography variant="body1">No results found.</Typography>
    )}
  </Box>
</Drawer>





</Card>

  );
};

export default CardCustom;
