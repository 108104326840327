import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  InputBase,
  IconButton,
  Button,
  Box,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CustomCard from './card';
import '../App.css';
import SearchComponent from './search';
import CardsGrid from './cardgrid';
import BtnSky from './btnSky';

function Main() {
  const [allCards, setAllCards] = useState([]); // Tutte le card ricevute dall'API
  const [displayedCards, setDisplayedCards] = useState([]); // Card attualmente visibili
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const CARDS_PER_PAGE = 30;
   const [loading, setLoading] = useState(true);

  // Recupera tutte le card dall'API all'avvio
  const fetchAllCards = async () => {
    try {
      const response = await fetch('https://hiddenvideos.vip/api/cards', {
        method: 'GET',
        headers: {
          Authorization: '=1l<*uq8Ks8Fhxg5-]b',
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      setAllCards(data);
      setDisplayedCards(data.slice(0, CARDS_PER_PAGE)); // Mostra solo le prime card
      setHasMore(data.length > CARDS_PER_PAGE); // Determina se ci sono altre card
       setLoading(false);
    } catch (error) {
      console.error('Errore nel recuperare le card:', error);
    }
  };

  useEffect(() => {
    fetchAllCards(); // Recupera le card all'avvio
  }, []);



  // Carica altre card (paginazione lato client)
  const handleLoadMore = () => {
    const nextPage = page + 1;
    const startIndex = page * CARDS_PER_PAGE;
    const endIndex = startIndex + CARDS_PER_PAGE;

    if (startIndex < allCards.length) {
      setDisplayedCards((prevCards) => [
        ...prevCards,
        ...allCards.slice(startIndex, endIndex),
      ]);
      setPage(nextPage);
      setHasMore(endIndex < allCards.length);
    } else {
      setHasMore(false);
    }
  };

  return (
   <>
  <Container maxWidth={false} sx={{ px: 2 }}>
    {/* Barra di ricerca */}
   <Grid container spacing={2} alignItems="center" sx={{ mt: 3 }}>
  <Grid item xs={12}>
    <SearchComponent
      allCards={allCards}
      CARDS_PER_PAGE={CARDS_PER_PAGE}
      setDisplayedCards={setDisplayedCards}
      setHasMore={setHasMore}
      setPage={setPage}
    />
  </Grid>
</Grid>

    {/* Grid delle card: assicurati che CardsGrid non imponga una larghezza fissa */}
    <CardsGrid displayedCards={displayedCards} />

{!loading && hasMore && (
 <Box
  sx={{
    mt: 3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    px: { xs: 2, sm: 4 },
    py: 2,
  }}
  onClick={handleLoadMore}
>

  <BtnSky>
    LOAD MORE
  </BtnSky>
</Box>


)}

  </Container>
</>

  );
}

export default Main;
