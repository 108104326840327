// src/components/MyAnimatedButton.jsx
import React from "react";
import { Button } from "@mui/material";
import { keyframes } from "@mui/system";

// Definisci i keyframes per lo "shading" che scorre
const darkenAnimation = keyframes`
  0% {
    left: -50%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
`;

export default function Btn({ children, ...props }) {
  return (
    <Button
      variant="contained"
      {...props}
      sx={{
        position: "relative",
        fontSize: "1rem",
        marginLeft: "1.3rem",
        padding: "1px",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        fontWeight: "bold",
        background: `
          radial-gradient(
            circle at center,
            rgba(255, 0, 0, 0.3) 5%,
            rgba(44, 44, 44, 0.5) 20%,
            rgba(44, 44, 44, 0.5) 100%
          )
        `,
        backdropFilter: "blur(6px)",
        borderRadius: "8px",
        borderWidth: "0.5px",
        borderStyle: "solid",
        borderColor: "darkred",
        color: "#fff",
        boxShadow: "none",
        overflow: "hidden",
        "&:hover": {
          boxShadow: "none",
          background: `
            radial-gradient(
              circle at center,
              rgba(255, 0, 0, 0.4) 5%,
              rgba(44, 44, 44, 0.6) 20%,
              rgba(44, 44, 44, 0.6) 100%
            )
          `,
        },

        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: "-50%",
          width: "50%",
          height: "100%",
          background: "linear-gradient(to right, transparent, rgba(0,0,0,0.8), transparent)",
          animation: `${darkenAnimation} 3s infinite`,
        },
      }}

    >
      {children}
    </Button>
  );
}
