import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  ClickAwayListener,
  Paper,
  InputBase,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Link,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import Btn from './btn';
import MenuIcon from '@mui/icons-material/Menu'; // Icona per il menu a discesa
import { Link as RouterLink } from 'react-router-dom'; // Link di react-router-dom
import BtnSky from './btnSky';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom'; // Hook per la navigazione
import { keyframes } from "@mui/system";





const darkenAnimation = keyframes`
  0% {
    left: -50%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
`;

function md5ToBase64(url) {
  // Step 1: Convert the URL to an MD5 hash
  const md5Hash = CryptoJS.MD5(url).toString();

  // Step 2: Create an array with the hash and the additional value
  const array = [md5Hash, "22dcbe26d606977f9484257ea466af9254979b97"];

  // Step 3: Convert the array to a JSON string
  const jsonString = JSON.stringify(array);

  // Step 4: Encode the JSON string to base64
  const base64String = btoa(jsonString);

  return base64String;
}

// Funzione debounce per ridurre le chiamate durante la digitazione
function debounce(func, delay) {
  let timer;
  return (...args) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
}

export default function SearchComponent({
  allCards,
  CARDS_PER_PAGE,
  setDisplayedCards,
  setHasMore,
  setPage,
}) {
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [open, setOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState("desc"); // Ora non usato
  const location = useLocation();
  const [randomCard, setRandomCard] = useState(null); // Modifica da [] a null
  const navigate = useNavigate(); // Hook per la navigazione

  const [selectedCategory, setSelectedCategory] = useState("");
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`https://hiddenvideos.vip/api/categories`, {
                method: 'GET',
                headers: {
                  'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
                  'Content-Type': 'application/json'
                }
              });
        if (response.ok) {
          const data = await response.json();
          // Si assume che data sia un array di oggetti contenenti almeno { id, name }
          setCategories(data);
          console.log(data)
        } else {
          console.error("Errore nel fetch delle categorie:", response.statusText);
        }
      } catch (error) {
        console.error("Errore nel fetch delle categorie:", error);
      }
    };

    fetchCategories();
  }, []);


 const handleCategoryChange = (event) => {
      const selected = event.target.value;
    setSelectedCategory(selected);
    navigate(`/category/${selected}`);
  };

  // Rimuove duplicati ignorando il case
  const removeDuplicatesByTitle = (cardsArray) => {
    const seen = new Set();
    return cardsArray.filter((card) => {
      const lowerTitle = card.title.toLowerCase();
      if (seen.has(lowerTitle)) return false;
      seen.add(lowerTitle);
      return true;
    });
  };

  // Funzione di ricerca che filtra, ordina e aggiorna gli stati
  const performSearch = useCallback(
    (query) => {
      setPage(1);
      if (query) {
        let filteredCards = allCards.filter((card) =>
          card.title.toLowerCase().includes(query.toLowerCase())
        );
        // Ordina i risultati in base al tempo (dal più nuovo al più vecchio)
        filteredCards.sort((a, b) => {
          const dateA = new Date(a.time);
          const dateB = new Date(b.time);
          return dateB - dateA;
        });
        const uniqueSuggestions = removeDuplicatesByTitle(filteredCards).slice(0, 20);
        setDisplayedCards(filteredCards.slice(0, CARDS_PER_PAGE));
        setHasMore(filteredCards.length > CARDS_PER_PAGE);
        setSuggestions(uniqueSuggestions);
        setOpen(true);
      } else {
        setDisplayedCards(allCards.slice(0, CARDS_PER_PAGE));
        setHasMore(allCards.length > CARDS_PER_PAGE);
        setSuggestions([]);
        setOpen(false);
      }
    },
    [allCards, CARDS_PER_PAGE, setDisplayedCards, setHasMore, setPage]
  );

  const debouncedSearch = useCallback(debounce(performSearch, 300), [performSearch]);

  const handleSearch = (query) => {
    setSearchQuery(query);
    debouncedSearch(query);
  };

  const handleSelectSuggestion = (title) => {
    setSearchQuery(title);
    performSearch(title);
    setOpen(false);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  // Effettua il check della query string per "profile" e attiva la ricerca se presente
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const profile = params.get("profile");
    if (profile) {
      setSearchQuery(profile);
      performSearch(profile);
    }
  }, [location.search, performSearch]);

  // Funzione per resettare la ricerca
  const handleClearSearch = () => {
    setSearchQuery("");
    performSearch("");
  };

  const fetchRandomCard = async () => {
    try {
      const response = await fetch(`https://hiddenvideos.vip/api/random`, {
        method: 'GET',
        headers: {
          'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setRandomCard(data);

      if (data.id) {
           let param = md5ToBase64(`https://hiddenvideos.vip/card/${data.id}`)
        navigate(`/card/${data.id}?u=${param}`);
      }
    } catch (error) {
      console.error('Error fetching random card:', error);
    }
  };

  return (
   <Box
      sx={{
        display: "flex",
        width: "100%",
        maxWidth: "900px", // regola in base alle tue necessità
        mx: "auto",
        mt: 3,
        gap: 2,
      }}
    >
      {/* Colonna Sinistra: Search, tasto Category e Select */}
      <Box sx={{ flex: 1 }}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box sx={{ position: "relative" }}>
            <Paper
              component="form"
              sx={{
                backgroundColor: "#1c1c1c",
                color: "#fff",
                borderRadius: "8px",
                p: "0 8px",
                display: "flex",
                alignItems: "center",
                height: "48px",
                boxShadow: "none",
                border: "1px solid #333",
              }}
              onSubmit={(e) => e.preventDefault()}
            >
              <SearchIcon sx={{ color: "#fff", mr: 1 }} />
              <InputBase
                placeholder="Search..."
                inputProps={{ "aria-label": "search" }}
                value={searchQuery}
                onChange={(e) => handleSearch(e.target.value)}
                sx={{
                  flex: 1,
                  color: "#fff",
                  fontFamily: "Inter, sans-serif",
                  fontSize: "16px",
                  "::placeholder": { color: "#aaa" },
                }}
              />
              <IconButton
                type="button"
                sx={{ p: 1, color: "#fff" }}
                aria-label="search"
                onClick={handleClearSearch}
              >
                <SearchIcon />
              </IconButton>
            </Paper>

            {/* Dropdown dei suggerimenti */}
            {open && suggestions.length > 0 && (
              <Paper
                sx={{
                  position: "absolute",
                  top: "56px",
                  left: 0,
                  right: 0,
                  backgroundColor: "#1c1c1c",
                  color: "#fff",
                  borderRadius: "8px",
                  border: "1px solid #333",
                  mt: 1,
                  maxHeight: "300px",
                  overflowY: "auto",
                  zIndex: 10,
                }}
              >
                <List sx={{ py: 0 }}>
                  {suggestions.map((item, index) => (
                    <ListItem
                      button
                      key={index}
                      sx={{ "&:hover": { backgroundColor: "#2c2c2c" } }}
                      onClick={() => handleSelectSuggestion(item.title)}
                    >
                      <ListItemIcon sx={{ minWidth: "32px" }}>
                        <TrendingUpIcon sx={{ color: "#FF3B30" }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={item.title}
                        primaryTypographyProps={{ fontFamily: "Inter, sans-serif" }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            )}
          </Box>
        </ClickAwayListener>

        {/* Pulsanti e Select */}
       <Box
  sx={{
    mt: 2,
    display: "flex",
    gap: 1,
    flexWrap: "wrap",
    alignItems: "center",
  }}
>
  <Btn component={RouterLink} to="/models" underline="none">
    Models
  </Btn>

  <FormControl
  variant="outlined"
  size="small"
  sx={{
    position: "relative",
    width: "7rem",
    fontSize: "1rem",
    marginLeft: "1.3rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    fontWeight: "bold",
    background: `
      radial-gradient(
        circle at center,
        rgba(255, 0, 0, 0.3) 5%,
        rgba(44, 44, 44, 0.5) 20%,
        rgba(44, 44, 44, 0.5) 100%
      )
    `,
    backdropFilter: "blur(6px)",
    borderRadius: "8px",
    borderWidth: "0.5px",
    borderStyle: "solid",
    borderColor: "darkred",
    color: "#fff",
    boxShadow: "none",
    overflow: "hidden",
    "&:hover": {
      boxShadow: "none",
      background: `
        radial-gradient(
          circle at center,
          rgba(255, 0, 0, 0.4) 5%,
          rgba(44, 44, 44, 0.6) 20%,
          rgba(44, 44, 44, 0.6) 100%
        )
      `,
    },
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: "-50%",
      width: "50%",
      height: "100%",
      background: "linear-gradient(to right, transparent, rgba(0,0,0,0.8), transparent)",
      // Se hai l’animazione darkenAnimation, abilita questa riga:
      // animation: `${darkenAnimation} 3s infinite`,
    },
  }}
>
  <InputLabel
    id="category-select-label"
    sx={{
      color: "#fff",
      fontWeight: "bold",
      fontSize: "0.95rem",
      textShadow: "1px 1px 2px rgba(0, 0, 0, 0.6)",
      zIndex: 1,
    }}
  >
    CATEGORY
  </InputLabel>

  <Select
    labelId="category-select-label"
    id="category-select"
    value={selectedCategory}
    onChange={handleCategoryChange}
    label="Category"
    sx={{
      color: "#fff",
      fontSize: "0.95rem",
      borderRadius: "8px",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },
      "& .MuiOutlinedInput-input": {
        padding: "6px 0px",
      },
      ".MuiSvgIcon-root": {
        color: "#fff",
      },
    }}
    MenuProps={{
      PaperProps: {
        sx: {
          backgroundColor: "#2c2c2c",
          color: "#fff",
          borderRadius: "8px",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)",
        },
      },
    }}
  >
    {/* Primo elemento: "Category" che porta a /category */}
    <MenuItem
      component={RouterLink}
      to="/category"
      sx={{
        fontSize: "0.95rem",
        "&:hover": {
          backgroundColor: "#444",
        },
      }}
    >
      Category
    </MenuItem>

    {/* Gli altri item provenienti dall’array categories */}
    {categories.map((cat) => (
      <MenuItem
        key={cat.id}
        value={cat.id}
        sx={{
          fontSize: "0.95rem",
          "&:hover": {
            backgroundColor: "#444",
          },
          "&.Mui-selected": {
            backgroundColor: "#555",
          },
          "&.Mui-selected:hover": {
            backgroundColor: "#666",
          },
        }}
      >
        {cat.name}
      </MenuItem>
    ))}
  </Select>
</FormControl>


          <Link onClick={fetchRandomCard}>
            <Btn>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginRight: "4px" }}
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-dices h-4 w-4 inline-block mr-2"
              >
                <rect width="12" height="12" x="2" y="10" rx="2" ry="2"></rect>
                <path d="m17.92 14 3.5-3.5a2.24 2.24 0 0 0 0-3l-5-4.92a2.24 2.24 0 0 0-3 0L10 6"></path>
                <path d="M6 18h.01"></path>
                <path d="M10 14h.01"></path>
                <path d="M15 6h.01"></path>
                <path d="M18 9h.01"></path>
              </svg>
              Random
            </Btn>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
