// UniqueCardsPage.jsx
import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Box,
  CircularProgress
} from '@mui/material';
import CardCustom from "./cardModel"; // Il tuo componente card


const UniqueCardsPage = () => {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortOrder, setSortOrder] = useState('desc'); // "desc": più nuovo → più vecchio

  useEffect(() => {
    const fetchUniqueCards = async () => {
      try {
        const response = await fetch('https://hiddenvideos.vip/api/unique-cards', {
         headers: {
          Authorization: '=1l<*uq8Ks8Fhxg5-]b',
          'Content-Type': 'application/json',
        },
        });



        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log(data)
        setCards(data);
      } catch (err) {
        console.error('Error fetching unique cards:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchUniqueCards();
  }, []);

  // Ordina le card. Se il campo time esiste (ISO string), ordina in base ad esso.
  const sortedCards = [...cards].sort((a, b) => {
    if (a.time && b.time) {
      return sortOrder === 'desc'
        ? new Date(b.time) - new Date(a.time)
        : new Date(a.time) - new Date(b.time);
    }
    // Fallback: ordina alfabeticamente per titolo (case-insensitive)
    return sortOrder === 'desc'
      ? b.title.toLowerCase().localeCompare(a.title.toLowerCase())
      : a.title.toLowerCase().localeCompare(b.title.toLowerCase());
  });

  if (loading) {
    return (
      <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "200px",
        p: 2,
        position: "relative",
      }}
    >
      {/* Spinner di caricamento in rosso e ingrandito */}
      <CircularProgress size={100} thickness={4} sx={{ color: "red" }} />
      {/* Icona (logo) centrata */}
      <Box
        component="img"
        src="/favicon.jpeg" // Sostituisci con il percorso del tuo logo
        alt="Logo"
        sx={{
          position: "absolute",
          width: { xs: "60px", sm: "80px", md: "100px" },
        }}
      />
    </Box>
    );
  }

  return (
   <>


     <Box sx={{ mt: 3, mx: "auto", px: { xs: 2, sm: 4, md: 2 } }}>
  <Grid container columnSpacing={1} rowSpacing={3} justifyContent="center">
    {sortedCards.map((card) => (
      <Grid item key={card.id} xs={12} sm={6} md={3}>
        <CardCustom
          title={card.title}
          category={card.category}
          time={card.time}
          imageUrl={card.imageUrl}
          id={card.id}
        />
      </Grid>
    ))}
  </Grid>
</Box>

    </>
  );
};

export default UniqueCardsPage;
