import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Grid, Typography, Button, Link, Box } from '@mui/material';
import CustomCard from './card'; // Assicurati che il percorso sia corretto
import BtnSky from './btnSky';
import CardsGrid from './cardgrid';

const CategoryDetail = () => {
  const { id } = useParams(); // Ottieni l'ID della categoria dalla URL
  const [cards, setCards] = useState([]); // Array delle card
  const [categoryName, setCategoryName] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // Stato per il numero di card visualizzate (carica inizialmente 9 card)
  const [visibleCards, setVisibleCards] = useState(9);

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response = await fetch(`https://hiddenvideos.vip/api/cards-by-category/${id}`, {
          method: 'GET',
          headers: {
            'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        if (Array.isArray(data.cards)) {
          setCategoryName(data.categoryName);
          setCards(data.cards);
        } else {
          throw new Error('Unexpected response format');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCards();
  }, [id]);

  // Funzione per caricare altre card
  const handleLoadMore = () => {
    // Carica 9 card in più, fino a raggiungere il totale disponibile
    setVisibleCards(prev => Math.min(prev + 9, cards.length));
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
    <Container maxWidth={false} sx={{ px: 2 }}>
   <Box
  sx={{
    display: "flex",
    justifyContent: "center",
    mt: 4,
    mb: 2
  }}
>
  <Typography
    variant="h4"
    sx={{
      position: 'relative',
      display: 'inline-block',     // mantiene solo la larghezza del testo
      fontWeight: 'bold',
      textTransform: 'uppercase',
      letterSpacing: '2px',
      color: '#fff',
      textShadow: '0 0 6px rgba(255, 0, 0, 0.6)',

      // Pseudo-elemento per l'alone radiale dietro il testo
      '&::before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '200%',
        height: '200%',
        borderRadius: '50%',
        background: `
          radial-gradient(
            circle,
            rgba(255, 0, 0, 0.4) 0%,
            rgba(44, 44, 44, 0) 60%
          )
        `,
        filter: 'blur(18px)',
        zIndex: -1,
      },
    }}
  >
    {categoryName}
  </Typography>
</Box>



     <CardsGrid
  displayedCards={cards.slice(0, visibleCards)}
  loading={loading}
/>


      {/* Mostra il pulsante Load More solo se ci sono altre card da caricare */}
      {visibleCards < cards.length && (
        <Grid container justifyContent="center" sx={{ mt: 3 }}>

          <Link onClick={handleLoadMore}>
             <BtnSky>
              LOAD MORE
            </BtnSky>
          </Link>
        </Grid>
      )}
      </Container>
    </>

  );
};

export default CategoryDetail;
