
import React, { useState } from 'react';
import { Form, Input, Button, Card, message } from 'antd';

export default function AddCategory() {
  const [form] = Form.useForm();
  const [previewData, setPreviewData] = useState(null);

  const onFinish = async (values) => {
    try {
      const response = await fetch('https://hiddenvideos.vip/api/add-category', {
        method: 'POST',
        headers: {
          'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(values)
      });
      const data = await response.json();
      if (!response.ok) throw new Error(data.message);
      message.success('Categoria aggiunta con successo');
      form.resetFields();
      setPreviewData(null);
    } catch (error) {
      message.error('Errore durante la creazione della categoria');
    }
  };

  const onValuesChange = (changedValues, allValues) => {
    setPreviewData(allValues);
  };

  return (
    <div style={{ padding: 20 }}>
      <h2>Aggiungi Categoria</h2>
      <Form form={form} layout="vertical" onFinish={onFinish} onValuesChange={onValuesChange}>
        <Form.Item
          name="name"
          label="Nome"
          rules={[{ required: true, message: 'Inserisci il nome della categoria' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="urlimg"
          label="URL Immagine"
          rules={[{ required: true, message: 'Inserisci l\'URL dell\'immagine' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Aggiungi Categoria
          </Button>
        </Form.Item>
      </Form>
      {previewData && (
        <div style={{ marginTop: 20 }}>
          <h3>Anteprima Categoria</h3>
          <Card
            title={previewData.name || "Nome Categoria"}
            cover={
              previewData.urlimg ? (
                <img alt="categoria" src={previewData.urlimg} style={{ height: 200, objectFit: 'cover' }} />
              ) : null
            }
          >
            <p>{previewData.name || "Nome Categoria"}</p>
          </Card>
        </div>
      )}
    </div>
  );
}
