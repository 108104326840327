 import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, Select, Switch, Checkbox, message } from 'antd';

export default function AdvCards() {
  const [advCards, setAdvCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingAdv, setEditingAdv] = useState(null);
  const [form] = Form.useForm();

  // Recupera la lista delle ADVCard
  const fetchAdvCards = async () => {
    setLoading(true);
    try {
      const response = await fetch(`https://hiddenvideos.vip/api/adv-cards`, {
        method: 'GET',
        headers: {
          'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      });
      const data = await response.json();
      console.log(data);
      setAdvCards(data);
    } catch (error) {
      message.error('Errore nel recupero degli ADV');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAdvCards();
  }, []);

  // Apertura della modal per la modifica
  const handleEdit = (record) => {
    setEditingAdv(record);
    // Imposta i valori del form con fallback di default
    form.setFieldsValue({
      titolo: record.titolo || "",
      descrizione: record.descrizione || "",
      sconto: record.sconto || "",
      titoloPulsante: record.titoloPulsante || "",
      colorePulsante: record.colorePulsante || "#ff0000", // default
      urlimage: record.urlimage || "",
      spawnFrequency: record.spawnFrequency || 1,
      link: record.link || "",
      visible: !(record.hide === 'true' || record.hide === true),
    });
  };

  // Eliminazione
  const handleDelete = async (id) => {
    try {
      await fetch(`${process.env.SERVER_HOST}/api/adv-cards/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      });
      message.success('ADVCard eliminata');
      fetchAdvCards();
    } catch (error) {
      message.error('Errore durante l\'eliminazione');
    }
  };

  // Aggiornamento (modal)
  const handleUpdate = async () => {
    try {
      const values = await form.validateFields();
      // Converti visible in hide: se visible è true, allora hide deve essere false e viceversa
      const updatedValues = {
        ...values,
        hide: !values.visible
      };
      delete updatedValues.visible;

      await fetch(`https://hiddenvideos.vip/api/adv-cards/${editingAdv.id}`, {
        method: 'PUT',
        headers: {
          'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(updatedValues)
      });

      message.success('ADVCard aggiornata');
      setEditingAdv(null);
      fetchAdvCards();
    } catch (error) {
      message.error('Errore durante l\'aggiornamento');
    }
  };

  // Aggiornamento della visibilità tramite Checkbox
  // Se il Checkbox è checked allora la ADVCard è visibile (hide = false)
  const handleToggleVisibility = async (checked, record) => {
    const newHide = !checked; // se checked true => hide false, se checked false => hide true
    try {
      await fetch(`https://hiddenvideos.vip/api/adv-cards/${record.id}`, {
        method: 'PUT',
        headers: {
          'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        // Se il backend si aspetta stringhe, convertiamo il boolean
        body: JSON.stringify({ hide: newHide ? 'true' : 'false' })
      });
      message.success('Visibilità aggiornata');
      setAdvCards(prev =>
        prev.map(card =>
          card.id === record.id ? { ...card, hide: newHide ? 'true' : 'false' } : card
        )
      );
    } catch (error) {
      message.error('Errore durante l\'aggiornamento della visibilità');
    }
  };

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'Titolo', dataIndex: 'titolo', key: 'titolo' },
    { title: 'Descrizione', dataIndex: 'descrizione', key: 'descrizione' },
    {
      title: 'Sconto',
      dataIndex: 'sconto',
      key: 'sconto',
      render: (text) => (text && text !== 'null' ? text : '-')
    },
    { title: 'Titolo Pulsante', dataIndex: 'titoloPulsante', key: 'titoloPulsante' },
    // Modifica: il dataIndex per link ora è "link" (minuscolo)
    {
      title: 'Link',
      dataIndex: 'link',
      key: 'link',
      render: (link) =>
        link ? <a href={link} target="_blank" rel="noopener noreferrer">{link}</a> : '-'
    },
    {
      title: 'Colore Pulsante',
      dataIndex: 'colorePulsante',
      key: 'colorePulsante',
      render: (color) => (
        <span style={{ background: color, padding: '0 10px', color: '#fff' }}>
          {color}
        </span>
      )
    },
    {
      title: 'URL Immagine',
      dataIndex: 'urlimage',
      key: 'urlimage',
      render: (url) =>
        url ? <a href={url} target="_blank" rel="noopener noreferrer">{url}</a> : '-'
    },
    {
      title: 'Frequenza di Spawn',
      dataIndex: 'spawnFrequency',
      key: 'spawnFrequency',
      render: (value) => (value && value !== 'null' ? value : '-')
    },
    {
      title: 'Visibilità',
      dataIndex: 'hide',
      key: 'hide',
      render: (_, record) => (
        <Checkbox
          // Il Checkbox è checked se la ADVCard è visibile (ossia se hide non è 'true' o true)
          checked={!(record.hide === 'true' || record.hide === true)}
          onChange={(e) => handleToggleVisibility(e.target.checked, record)}
        />
      )
    },
    {
      title: 'Azioni',
      key: 'actions',
      render: (_, record) => (
        <>
          <Button onClick={() => handleEdit(record)}>Modifica</Button>
          <Button danger onClick={() => handleDelete(record.id)} style={{ marginLeft: 8 }}>
            Elimina
          </Button>
        </>
      )
    }
  ];

  return (
    <div style={{ padding: 20 }}>
      <h2>Lista ADVCard</h2>
      <Table dataSource={advCards} columns={columns} rowKey="id" loading={loading} />
      <Modal
        title="Modifica ADVCard"
        visible={!!editingAdv}
        onOk={handleUpdate}
        onCancel={() => setEditingAdv(null)}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="titolo"
            label="Titolo"
            rules={[{ required: true, message: 'Inserisci il titolo' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="descrizione"
            label="Descrizione"
            rules={[{ required: true, message: 'Inserisci la descrizione' }]}
          >
            <Input.TextArea rows={3} />
          </Form.Item>
          <Form.Item name="sconto" label="Sconto">
            <Input placeholder="Inserisci lo sconto (opzionale)" />
          </Form.Item>
          <Form.Item
            name="titoloPulsante"
            label="Titolo Pulsante"
            rules={[{ required: true, message: 'Inserisci il titolo del pulsante' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="colorePulsante"
            label="Colore Pulsante"
            rules={[{ required: true, message: 'Inserisci il colore del pulsante' }]}
          >
            <Input type="color" />
          </Form.Item>
          <Form.Item name="urlimage" label="URL Immagine">
            <Input placeholder="Inserisci l'URL dell'immagine (opzionale)" />
          </Form.Item>
          <Form.Item
            name="spawnFrequency"
            label="Frequenza di Spawn (ogni quante card)"
            rules={[{ required: true, message: 'Inserisci la frequenza di spawn' }]}
          >
            <Input type="number" placeholder="Inserisci la frequenza" />
          </Form.Item>
          <Form.Item
            name="link"
            label="Link"
            rules={[{ required: true, message: 'Inserisci il link' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="visible"
            label="Visibilità"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
