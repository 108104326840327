import React from "react";
import { Button } from "@mui/material";

export default function BtnSky({ children, ...props }) {
  return (
    <>
      <style>
        {`
          @keyframes gradientAnimation {
            0% {
              background-position: 0% 50%;
            }
            50% {
              background-position: 100% 50%;
            }
            100% {
              background-position: 0% 50%;
            }
          }
        `}
      </style>
      <Button
        variant="contained"
        {...props}
        sx={{
          position: "relative",
          fontSize: "1rem",
          ml: "1.3rem",
          px: "1rem",
          py: "0.5rem",
          fontWeight: "bold",
          background: "linear-gradient(135deg, #80d0f7, #0077B6, #80d0f7)",
          backgroundSize: "200% 200%",
          animation: "gradientAnimation 6s ease infinite",
          color: "#F5F5F5",
          fontFamily: "Inter, sans-serif",
          border: "none",
          borderRadius: "12px",
          display: "inline-flex",
          alignItems: "center",
          gap: "6px",
          cursor: "pointer",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.25)",
          height: "2.9rem",
          overflow: "hidden",
          transition: "transform 0.4s ease, box-shadow 0.4s ease",
          "&:hover": {
            transform: "translateY(-2px)",
            boxShadow: "0 6px 12px rgba(0,0,0,0.4)",
          },
        }}
      >
        {children}
      </Button>
    </>
  );
}
