import React from 'react';
import { Container, Typography, Box, Button, Link } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import SiteLink from './sitelink';
import EmailLink from './email';
import { site, emailcontact } from './config';

const TermsOfService = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  let section = query.get('q');

  // Se nessuna sezione è selezionata, impostiamo 'tos' di default
  if (!section) {
    section = 'tos';
  }

  const handleSectionChange = (sectionName) => {
    navigate(`/tos?q=${sectionName}`);
  };

  // Funzione per renderizzare il contenuto in base alla sezione selezionata
  const renderContent = () => {
    switch (section) {
      case 'tos':
        return (
          <Box>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', mb: 2 }}>
              Terms of Service
            </Typography>
            <Typography variant="h6" gutterBottom>
              1. Acceptance of Terms
            </Typography>
            <Typography variant="body1" paragraph>
              By accessing and using <SiteLink>{site}</SiteLink>, you agree to be bound by these Terms of Service.
              If you do not agree with any part of these terms, you must immediately cease use of our website.
            </Typography>
            <Typography variant="h6" gutterBottom>
              2. Eligibility and Account Creation
            </Typography>
            <Typography variant="body1" paragraph>
              You must be at least 18 years old to use <SiteLink>{site}</SiteLink>. You are responsible for maintaining
              the confidentiality of your account credentials and activities associated with your account. You are
              prohibited from creating multiple accounts or sharing your account with others.
            </Typography>
            <Typography variant="h6" gutterBottom>
              3. Our Services
            </Typography>
            <Typography variant="body1" paragraph>
              <SiteLink>{site}</SiteLink> provides a platform that indexes and organizes leaked data. We do not host,
              create, or manage the data ourselves. Fees associated with our services are outlined in the complete Terms
              of Service. Prohibited activities include, but are not limited to, scraping our website, using our services
              for illegal activities, or using our services in a manner that may harm our reputation.
            </Typography>
            <Typography variant="h6" gutterBottom>
              4. Supported Digital Assets
            </Typography>
            <Typography variant="body1" paragraph>
              Certain digital assets or services may not be available, or may only be available in certain jurisdictions.
              We reserve the right to discontinue support for certain digital assets or services at any time without prior
              notice.
            </Typography>
            <Typography variant="h6" gutterBottom>
              5. Dispute Resolution
            </Typography>
            <Typography variant="body1" paragraph>
              In the event of a dispute, all claims must be resolved through arbitration as outlined in the complete Terms
              of Service.
            </Typography>
            <Typography variant="h6" gutterBottom>
              6. Enforcement and Blacklisting
            </Typography>
            <Typography variant="body1" paragraph>
              We take violations of our Terms of Service very seriously. If you are found to be in breach of our terms, we
              reserve the right to immediately suspend or terminate your access to <SiteLink>{site}</SiteLink> without notice.
              This includes, but is not limited to, engaging in fraudulent activity, misrepresenting chargebacks, or
              submitting false claims regarding transactions. We also reserve the right to blacklist any user from our site,
              preventing further access without prior notice or appeal. Users are strictly prohibited from contacting our support
              team regarding any matters related to fraudulent activity or chargebacks once action has been taken.
            </Typography>
            <Typography variant="h6" gutterBottom>
              7. Future Changes
            </Typography>
            <Typography variant="body1" paragraph>
              We reserve the right to make changes to our services, platforms, or these terms. Your continued use of our
              services constitutes your acceptance of any such changes.
            </Typography>
          </Box>
        );
      case 'privacy':
        return (
          <Box>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', mb: 2 }}>
              Privacy Policy
            </Typography>
            <Typography variant="body1" paragraph>
              Here at <SiteLink>{site}</SiteLink> we take your privacy seriously and are committed to safeguarding your
              personal information. This Privacy Policy outlines how we collect, use, disclose, and protect your data when
              you access or interact with our website. Our goal is to ensure transparency, trust, and security as we handle
              your data responsibly and ethically.
            </Typography>
            <Typography variant="h6" gutterBottom>
              1. Information We Collect
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              1.1. Personal Information
            </Typography>
            <Typography variant="body1" paragraph>
              We may collect certain personal information from you, including:
              <br /><strong>IP Address:</strong> Your IP address is collected when you access our website.
              This information is used solely for the proper functioning of our services and is not used to track or
              identify users individually.
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              1.2. Non-Personal Information
            </Typography>
            <Typography variant="body1" paragraph>
              We may also collect non-personal information, such as:
              <br /><strong>Usage Data:</strong> We use tools like Google Analytics and other third-party services to collect
              anonymized usage data. This data helps us improve our website and better understand how users interact with our
              content.
            </Typography>
            <Typography variant="h6" gutterBottom>
              2. How We Use Your Information
            </Typography>
            <Typography variant="body1" paragraph>
              We use the information collected from you for the following purposes:
              <br />- To provide, maintain, and improve the functionality of our website and services.
              <br />- To analyze user behavior and improve the performance and content of our website.
              <br />- To fulfill legal obligations, including compliance with applicable data protection regulations.
              <br />- To develop and enhance new features, services, and product offerings.
              <br />- To communicate with you regarding website updates, customer service, marketing, and promotional efforts.
              <br />- To prevent fraud and ensure the security of our platform.
              <br />- To track activity through log files for troubleshooting, maintenance, and security purposes.
            </Typography>
            <Typography variant="h6" gutterBottom>
              3. Disclosure of Your Information
            </Typography>
            <Typography variant="body1" paragraph>
              We may share your information with the following parties:
              <br />- <strong>Third-party service providers:</strong> We use services such as Google Analytics to assist with data analysis,
              behavioral metrics, and performance improvements.
              <br />- <strong>Legal authorities:</strong> In compliance with applicable laws, we may disclose your information to law enforcement
              or government agencies when required.
              <br />Additionally, this site is protected by reCAPTCHA. Please refer to our Privacy Policy and Terms of Service for further
              details on data protection measures related to reCAPTCHA.
              <br />We partner with Google Analytics and Google Advertising to track user interactions through cookies, heatmaps, and session
              replay for the purpose of improving our products and services. This data helps us understand site usage, optimize performance,
              and enhance security.
              <br />For more information, please review the{' '}
              <Link
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ color: 'red', textDecoration: 'underline' }}
              >
                Google Privacy Statement
              </Link>.
            </Typography>
            <Typography variant="h6" gutterBottom>
              4. Security Measures
            </Typography>
            <Typography variant="body1" paragraph>
              We implement industry-standard security measures to protect your personal information.
              Passwords are securely hashed, and IP addresses are stored in hashed form to prevent unauthorized access.
              While we strive to protect your data, no method of transmission or storage over the internet is entirely secure,
              and we cannot guarantee absolute security.
            </Typography>
            <Typography variant="h6" gutterBottom>
              5. Your Rights
            </Typography>
            <Typography variant="body1" paragraph>
              You are entitled to the following rights regarding your personal data:
              <br />- <strong>Access, update, or delete:</strong> You may request access to, correction, or deletion of your personal information at any time.
              <br />- <strong>Opt-out of data collection:</strong> You may disable cookies and opt-out of data tracking through your browser settings.
              <br />- <strong>Lodge a complaint:</strong> If you believe your rights have been violated, you have the right to lodge a complaint with the relevant
              data protection authority.
              To exercise any of these rights, please contact us at <EmailLink>hiddenvideos@proton.me</EmailLink>.
            </Typography>
            <Typography variant="h6" gutterBottom>
              6. Children's Privacy
            </Typography>
            <Typography variant="body1" paragraph>
              <SiteLink>{site}</SiteLink> is not intended for individuals under the age of 18.
              We do not knowingly collect personal information from individuals under the age of 18.
              If we become aware that a child under 18 has provided us with personal data, we will take prompt steps to delete such information
              from our systems.
            </Typography>
            <Typography variant="h6" gutterBottom>
              7. Changes to this Privacy Policy
            </Typography>
            <Typography variant="body1" paragraph>
              We reserve the right to modify this Privacy Policy as necessary to reflect changes in our practices or to comply with legal requirements.
              We encourage you to review this policy periodically to stay informed of any updates.
            </Typography>
            <Typography variant="h6" gutterBottom>
              8. Contact Us
            </Typography>
            <Typography variant="body1" paragraph>
              If you have any questions, concerns, or requests related to this Privacy Policy or how we handle your personal information,
              please contact us at <EmailLink>hiddenvideos@proton.me</EmailLink>.
            </Typography>
          </Box>
        );
      case 'dmca':
        return (
          <Box>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', mb: 2 }}>
              DMCA
            </Typography>
            <Typography variant="h6" gutterBottom>
              FOR COPYRIGHT OWNERS
            </Typography>
            <Typography variant="body1" paragraph>
              We do not store any files or images on our server. <SiteLink>{site}</SiteLink> only indexes and links to content provided by other non-affiliated sites.
              If your copyrighted material has been posted on <SiteLink>{site}</SiteLink> or if hyperlinks to your copyrighted material are returned through our
              search engine, please contact us at <EmailLink>{emailcontact}</EmailLink>.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>DMCA Compliance Notice</strong>
            </Typography>
            <Typography variant="body1" paragraph>
              <SiteLink>{site}</SiteLink> does not host or store any files, images, or media content on its servers. The content indexed and linked on <SiteLink>{site}</SiteLink> is provided by third-party, non-affiliated websites.
              If copyrighted material belonging to you has been linked or indexed by <SiteLink>{site}</SiteLink>, or if hyperlinks to your copyrighted content appear
              through our search engine, please note that we do not have direct control over the hosted material.
            </Typography>
            <Typography variant="body1" paragraph>
              In such cases, you must contact the third-party websites where the infringing material is stored and request its removal directly from them.
              <br />
              Please refer to the{' '}
              <Link
                href="https://mega.io/terms"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ color: 'red', textDecoration: 'underline' }}
              >
                Mega.nz Terms of Service
              </Link>.
            </Typography>
            <Typography variant="body1" paragraph>
              However, if you believe that we can assist you with facilitating a takedown request or that a DMCA takedown notice should be filed with us,
              please direct your inquiries to <EmailLink>hiddenvideos@proton.me</EmailLink>.
            </Typography>
            <Typography variant="body1" paragraph>
              For non-copyright-related concerns, such as privacy violations, harassment, or unlawful/offensive content, please contact us at
              <EmailLink>hiddenvideos@proton.me</EmailLink>.
            </Typography>
          </Box>
        );
      case 'legal':
        return (
          <Box>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', mb: 2 }}>
              Legal Compliance
            </Typography>
            <Typography variant="body1" paragraph>
              All media content hosted on <SiteLink>{site}</SiteLink> is presented and regulated by third-party platforms. Every model featured on this
              site is at least 18 years of age, in full compliance with 18 U.S.C. 2257 and other applicable federal laws.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong><SiteLink>{site}</SiteLink> - 18 U.S.C. 2257 and Legal Statement:</strong> <SiteLink>{site}</SiteLink> is not the primary or secondary producer
              of any content found on this website, as defined under 18 U.S.C. 2257 and 28 C.F.R. 75. Content and age-verification records required by these
              statutes are maintained by the content producers and third-party platforms that host this content. For inquiries regarding specific record-keeping
              requirements, users should contact the originating website directly.
            </Typography>
            <Typography variant="body1" paragraph>
              In addition, <SiteLink>{site}</SiteLink> adheres to the following legal frameworks:
              <br /><strong>18 U.S.C. § 1466A:</strong> <SiteLink>{site}</SiteLink> does not permit content that depicts obscene visual representations involving minors
              or content that otherwise violates U.S. federal law regarding the production, distribution, or possession of illegal material.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>Revenge Porn Laws:</strong> <SiteLink>{site}</SiteLink> does not tolerate the non-consensual sharing of intimate images or videos, commonly known
              as “revenge porn.” Under various state laws (e.g., California Penal Code § 647(j)(4), New York Civil Rights Law § 52-b), it is illegal to distribute
              sexually explicit material without the consent of the individual depicted.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>18 U.S.C. §§ 2251-2258 (CSAM Provisions):</strong> <SiteLink>{site}</SiteLink> strictly prohibits the distribution, hosting, or sharing of Child Sexual
              Abuse Material (CSAM). Any content that violates these provisions will be immediately reported to law enforcement, and users engaging in such illegal
              activity will be permanently banned and subject to legal action. We fully comply with the reporting obligations under 18 U.S.C. § 2258A, which requires
              immediate reporting of CSAM to the National Center for Missing and Exploited Children (NCMEC).
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>Content Removal and Reporting:</strong> <SiteLink>{site}</SiteLink> will promptly review and remove any third-party uploaded content that violates our
              policies, terms of service, or legal standards. This includes content that is obscene, defamatory, or illegal, particularly any content related to CSAM or
              revenge porn. We are committed to cooperating with law enforcement in cases where such material is found on the platform.
            </Typography>
            <Typography variant="body1" paragraph>
              For further information on locating original content or to report content that may be in violation of federal law, including CSAM, please contact us at:{' '}
              <EmailLink>hiddenvideos@proton.me</EmailLink>.
            </Typography>
            <Typography variant="body1" paragraph>
              All visitors of this website are required to be over 18 years old (over 21 in some locations).
            </Typography>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Box sx={{ backgroundColor: '#1a1a1a', color: '#e0e0e0', p: 3, borderRadius: 2 }}>
        {/* Navigation Buttons sempre visibili */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
            justifyContent: 'center',
            flexWrap: 'wrap',
            mb: 3,
          }}
        >
          <Button
            variant="contained"
            onClick={() => handleSectionChange('tos')}
            sx={{
              borderRadius: '12px',
              background: section === 'tos' ? '#1a1a1a' : '#0d0d0d',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)',
              textTransform: 'none',
              fontWeight: 'bold',
              color: '#e0e0e0',
              transition: 'transform 0.2s, box-shadow 0.2s, background 0.2s',
              '&:hover': {
                transform: 'scale(1.03)',
                boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.7)',
                background: '#1a1a1a',
              },
            }}
          >
            Terms of Service
          </Button>
          <Button
            variant="contained"
            onClick={() => handleSectionChange('privacy')}
            sx={{
              borderRadius: '12px',
              background: section === 'privacy' ? '#1a1a1a' : '#0d0d0d',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)',
              textTransform: 'none',
              fontWeight: 'bold',
              color: '#e0e0e0',
              transition: 'transform 0.2s, box-shadow 0.2s, background 0.2s',
              '&:hover': {
                transform: 'scale(1.03)',
                boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.7)',
                background: '#1a1a1a',
              },
            }}
          >
            Privacy Policy
          </Button>
          <Button
            variant="contained"
            onClick={() => handleSectionChange('dmca')}
            sx={{
              borderRadius: '12px',
              background: section === 'dmca' ? '#1a1a1a' : '#0d0d0d',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)',
              textTransform: 'none',
              fontWeight: 'bold',
              color: '#e0e0e0',
              transition: 'transform 0.2s, box-shadow 0.2s, background 0.2s',
              '&:hover': {
                transform: 'scale(1.03)',
                boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.7)',
                background: '#1a1a1a',
              },
            }}
          >
            DMCA
          </Button>
          <Button
            variant="contained"
            onClick={() => handleSectionChange('legal')}
            sx={{
              borderRadius: '12px',
              background: section === 'legal' ? '#1a1a1a' : '#0d0d0d',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)',
              textTransform: 'none',
              fontWeight: 'bold',
              color: '#e0e0e0',
              transition: 'transform 0.2s, box-shadow 0.2s, background 0.2s',
              '&:hover': {
                transform: 'scale(1.03)',
                boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.7)',
                background: '#1a1a1a',
              },
            }}
          >
            Legal Compliance
          </Button>
        </Box>

        {/* Area di Contenuto */}
        {renderContent()}
      </Box>
    </Container>
  );
};

export default TermsOfService;
