import React from 'react';
import { Card, Box, Link, Typography, Button, Chip } from '@mui/material';
import { keyframes } from '@mui/system';
import { darken } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom'; // Link di react-router-dom

// Bordo arcobaleno ruotante
const rotateBorder = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Animazione gradiente sul titolo (sfumature di viola)
const swirlViolet = keyframes`
  0%   { background-position: 0% 50%; }
  50%  { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

// Effetto “bounce” per il pulsante
const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
  40% { transform: translateY(-10px); }
  60% { transform: translateY(-5px); }
`;

export default function AdvCardCustom({
  id,
  titolo,
  descrizione,
  sconto,
  titoloPulsante,
  colorePulsante,
  urlimage,
  hide,
  link
}) {
  const isHidden = hide === 'true' || hide === true;
  const discount = sconto && sconto !== 'null' ? sconto : null;
console.log(link)
  return (
    <Card
      sx={{
        width: 400,
        height: 670,
        borderRadius: 3,
        overflow: 'hidden',
        boxShadow: '0 4px 12px rgba(0,0,0,0.3)',
        backgroundColor: '#2c2c2c',
        position: 'relative',
        opacity: isHidden ? 0.5 : 1,
        p: '2px',
        // Bordo arcobaleno animato
        '&::before': {
          content: '""',
          position: 'absolute',
          top: '-2px',
          left: '-2px',
          right: '-2px',
          bottom: '-2px',
          zIndex: -1,
          borderRadius: 'inherit',
          background:
            'conic-gradient(#6c63ff, #b39ddb, #9c27b0, #ce93d8, #6c63ff)',
          animation: `${rotateBorder} 4s linear infinite`,
        },
      }}
    >
      {/* Sfondo blurato */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${urlimage || 'https://via.placeholder.com/400x670'})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'blur(6px)',
          zIndex: 1,
          opacity: 0.7,
        }}
      />

      {/* Header con titolo, sconto e descrizione */}
      <Box
        sx={{
          backgroundColor: 'rgba(0,0,0,0.85)',
          color: '#fff',
          p: 3,
          position: 'relative',
          zIndex: 2,
          mx: 2,
          mt: 2,
          borderRadius: '8px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              flexGrow: 1,
              // Gradiente viola animato
              background: `linear-gradient(
                90deg,
                #6c63ff,
                #9c27b0,
                #b39ddb,
                #ce93d8
              )`,
              backgroundSize: '200% 200%',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              animation: `${swirlViolet} 6s ease infinite`,
            }}
          >
            {titolo || 'ADV Title'}
          </Typography>
          {discount && (
            <Chip
              label={`${discount}% OFF`}
              size="big"
              sx={{
                backgroundColor: '#ec407a',  // colore sconto più rosa
                color: '#fff',
                fontWeight: 'bold',
                textShadow: '0 0 2px rgba(0,0,0,0.5)',
              }}
            />
          )}
        </Box>

        <Typography variant="body1" sx={{ color: '#e0e0e0' }}>
          {descrizione || 'Descrizione della ADV card.'}
        </Typography>
      </Box>

      {/* Immagine principale */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 3,
          position: 'relative',
          zIndex: 2,
        }}
      >
        {urlimage && (
          <Box
            component="img"
            src={urlimage}
            alt={titolo}
            sx={{
              width: '100%',
              height: 400,
              objectFit: 'cover',
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.3)',
            }}
          />
        )}
      </Box>

      {/* Pulsante con effetto bounce */}
      <Link
          component={RouterLink}
          to={link || '#'} // fallback ad '#' se link non è definito
          underline="none"
          target="_blank"
          rel="noopener noreferrer"
        >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 2,
          position: 'relative',
          zIndex: 2,
        }}
      >


        <Button
          variant="contained"
          fullWidth
           sx={{
        backgroundColor: colorePulsante || '#9c27b0',
        textTransform: 'none',
        fontWeight: 'bold',
        fontSize: '16px',
        borderRadius: '8px',
        py: 1.5,
        // Effetto bounce animato
        animation: `${bounce} 2.5s infinite`,
        '&:hover': {
          // Invece di un colore fisso, scurisci di un 20% il colore base
          backgroundColor: (theme) =>
            darken(colorePulsante || '#9c27b0', 0.2),
        },
      }}
        >
          {titoloPulsante || 'Action'}
        </Button>

      </Box>
      </Link>

    </Card>
  );
}
