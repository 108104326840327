import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Modal,
} from "@mui/material";
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
// Lista degli orari predefiniti
const timeOptions = [
  "00:03", "00:06", "01:03", "01:06", "02:03", "02:06",
  "03:03", "03:06", "04:03", "04:06", "05:03", "05:06",
  "06:03", "06:06", "07:03", "07:06", "08:03", "08:06",
  "09:03", "09:06", "10:03", "10:06", "11:03", "11:06",
  "12:03", "12:06", "13:03", "13:06", "14:03", "14:06",
  "15:03", "15:06", "16:03", "16:06", "17:03", "17:06",
  "18:03", "18:06", "19:03", "19:06", "20:03", "20:06",
  "21:03", "21:06", "22:03", "22:06", "23:03", "23:06"
];

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // Estraiamo solo la parte base64 dalla stringa "data:image/xxx;base64,....."
      const base64String = reader.result.split(",")[1];
      resolve(base64String);
    };
    reader.onerror = (error) => reject(error);
  });
}

// Funzione per caricare l'immagine su imgbb
async function uploadImageToImgbb(file) {
  try {
    const base64Image = await getBase64(file);
    const formData = new FormData();
    formData.append("image", base64Image);
    const apiKey = "22a18a9cf38af3f1ab5d3a3813e601e2";
    const url = `https://api.imgbb.com/1/upload?key=${apiKey}`;
    const response = await axios.post(url, formData);
    return response.data;
  } catch (error) {
    console.error("Errore durante il caricamento dell'immagine:", error);
    throw error;
  }
}


function PosterForm() {
  const routerLocation = useLocation();
  const query = new URLSearchParams(routerLocation.search);
  const id = query.get("id");

  const navigate = useNavigate();



  // Stati generici
  const [categories, setCategories] = useState([]);

  // Stati per il form
  const [postType, setPostType] = useState("onlyfans_plus");
  const [link, setLink] = useState("");
  const [nome, setNome] = useState("");
  const [gb, setGb] = useState("");
  const [img, setImg] = useState(null);
  const [imgPreview, setImgPreview] = useState(null);
  const [collaborazione, setCollaborazione] = useState("");
  const [nazionalita, setNazionalita] = useState("");
  const [durata, setDurata] = useState("");
  const [descrizione, setDescrizione] = useState("");
  const [postOnSite, setPostOnSite] = useState(false);
  const [category, setCategory] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [postDate, setPostDate] = useState("");
  const [postTime, setPostTime] = useState("");

  // 1) Controllo referrer (Telegram)
  /*

    fai passe un md5 con id più supersecret o jwttoken
  */
  useEffect(() => {
    const referrer = document.referrer;

     console.log(document)
    if (!referrer.includes("t.me") && !referrer.includes("telegram.me")) {
      // Se il referrer non è di Telegram, reindirizza alla home
      //navigate("/");
      return;
    }
  }, [navigate]);



  // 3) Carica le categorie da hiddenvideos.vip
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`https://hiddenvideos.vip/api/categories`, {
          method: "GET",
          headers: {
            Authorization: "=1l<*uq8Ks8Fhxg5-]b",
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const data = await response.json();
          setCategories(data);
        }
      } catch (err) {
        console.error("Errore nel recupero delle categorie:", err);
      }
    };
    fetchCategories();
  }, []);

  // 4) Gestione anteprima immagine
  useEffect(() => {
    if (img) {
      const objectUrl = URL.createObjectURL(img);
      setImgPreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    } else {
      setImgPreview(null);
    }
  }, [img]);

  // 5) Funzione per caricare la nuova categoria
  const handleAddCategory = () => {
    setCategory(newCategory);
    setNewCategory("");
  };

  // 6) Gestione change file
  const handleFileChange = (e) => {
    setImg(e.target.files[0]);
  };

  // 7) Gestione invio form
  const handleSubmit = async (e) => {
    e.preventDefault();
    let uploadedImageUrl = "";

    if (img) {
      try {
        const uploadResponse = await uploadImageToImgbb(img);
        uploadedImageUrl = uploadResponse.data.url || uploadResponse.data.data.url;
        console.log("Immagine caricata:", uploadedImageUrl);
      } catch (error) {
        console.error("Errore nel caricamento dell'immagine:", error);
      }
    }

    const postData = {
      type: postType,
      link: link,
      name: nome,
      linkImg: uploadedImageUrl || "",
      category: category,
      date: `${postDate} ${postTime}`,
      dati: {
        gb: gb,
        ...(postType === "onlyfans_ppv" && {
          colab: collaborazione,
          nation: nazionalita,
          durata: durata,
          descrizione: descrizione,
        }),
      },
      postOnU: postOnSite,
    };

    axios
      .post("https://hiddenvideos.vip/api/programming", postData, {
        headers: {
          Authorization: "=1l<*uq8Ks8Fhxg5-]b",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("Success:", response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };




  return (
    <Paper
      sx={{
        p: { xs: 2, sm: 4 },
        maxWidth: 600,
        mx: "auto",
        mt: 4,
        backgroundColor: "#1a1a1a",
        color: "#e0e0e0",
      }}
    >
      <Typography variant="h4" sx={{ mb: 3, textAlign: "center" }}>
        Poster Form
      </Typography>
      <form onSubmit={handleSubmit}>
        {/* Selezione della tipologia di post */}
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="post-type-label" sx={{ color: "#e0e0e0" }}>
            Seleziona Tipologia
          </InputLabel>
          <Select
            labelId="post-type-label"
            value={postType}
            label="Seleziona Tipologia"
            onChange={(e) => setPostType(e.target.value)}
            sx={{ color: "#e0e0e0", backgroundColor: "#333" }}
          >
            <MenuItem value="onlyfans_plus">Onlyfans +</MenuItem>
            <MenuItem value="onlyfans_mixed">Onlyfans Mixed</MenuItem>
            <MenuItem value="onlyfans_ppv">Onlyfans PPV</MenuItem>
          </Select>
        </FormControl>

        {/* Campi comuni per Onlyfans+ e Onlyfans Mixed */}
        {(postType === "onlyfans_plus" || postType === "onlyfans_mixed") && (
          <>
            <TextField
              label="Link"
              variant="filled"
              fullWidth
              value={link}
              onChange={(e) => setLink(e.target.value)}
              sx={{
                mb: 2,
                backgroundColor: "#333",
                input: { color: "#e0e0e0" },
              }}
            />
            <TextField
              label="Nome"
              variant="filled"
              fullWidth
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              sx={{
                mb: 2,
                backgroundColor: "#333",
                input: { color: "#e0e0e0" },
              }}
            />
            <TextField
              label="GB"
              variant="filled"
              fullWidth
              value={gb}
              onChange={(e) => setGb(e.target.value)}
              sx={{
                mb: 2,
                backgroundColor: "#333",
                input: { color: "#e0e0e0" },
              }}
            />
            <Button
              variant="contained"
              component="label"
              sx={{ mb: 2, backgroundColor: "#FF4081" }}
            >
              Carica Immagine
              <input type="file" hidden onChange={handleFileChange} />
            </Button>
          </>
        )}

        {/* Campi per Onlyfans PPV */}
        {postType === "onlyfans_ppv" && (
          <>
            <TextField
              label="Link"
              variant="filled"
              fullWidth
              value={link}
              onChange={(e) => setLink(e.target.value)}
              sx={{
                mb: 2,
                backgroundColor: "#333",
                input: { color: "#e0e0e0" },
              }}
            />
            <TextField
              label="Nome"
              variant="filled"
              fullWidth
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              sx={{
                mb: 2,
                backgroundColor: "#333",
                input: { color: "#e0e0e0" },
              }}
            />
            <TextField
              label="Collaborazione"
              variant="filled"
              fullWidth
              value={collaborazione}
              onChange={(e) => setCollaborazione(e.target.value)}
              sx={{
                mb: 2,
                backgroundColor: "#333",
                input: { color: "#e0e0e0" },
              }}
            />
            <TextField
              label="Nazionalità"
              variant="filled"
              fullWidth
              value={nazionalita}
              onChange={(e) => setNazionalita(e.target.value)}
              sx={{
                mb: 2,
                backgroundColor: "#333",
                input: { color: "#e0e0e0" },
              }}
            />
            <TextField
              label="Durata"
              variant="filled"
              fullWidth
              value={durata}
              onChange={(e) => setDurata(e.target.value)}
              sx={{
                mb: 2,
                backgroundColor: "#333",
                input: { color: "#e0e0e0" },
              }}
            />
            <TextField
              label="Descrizione"
              variant="filled"
              fullWidth
              multiline
              rows={3}
              value={descrizione}
              onChange={(e) => setDescrizione(e.target.value)}
              sx={{
                mb: 2,
                backgroundColor: "#333",
                input: { color: "#e0e0e0" },
              }}
            />
            <Button
              variant="contained"
              component="label"
              sx={{ mb: 2, backgroundColor: "#FF4081" }}
            >
              Carica Immagine
              <input type="file" hidden onChange={handleFileChange} />
            </Button>
          </>
        )}

        {/* Anteprima dell'immagine caricata */}
        {imgPreview && (
          <Box sx={{ mb: 2, textAlign: "center" }}>
            <img
              src={imgPreview}
              alt="Anteprima"
              style={{ maxWidth: "100%", borderRadius: "8px" }}
            />
          </Box>
        )}

        {/* Checkbox per Post sul sito */}
        <FormControlLabel
          control={
            <Checkbox
              checked={postOnSite}
              onChange={(e) => setPostOnSite(e.target.checked)}
              sx={{ color: "#FF4081" }}
            />
          }
          label="Posta su Universe69"
          sx={{ mb: 2, color: "#e0e0e0" }}
        />

        {/* Select per categorie */}
        <FormControl fullWidth sx={{ mb: 2 }}>
  <InputLabel id="category-label" sx={{ color: "#e0e0e0" }}>
    Categoria
  </InputLabel>
  <Select
    labelId="category-label"
    value={category}
    label="Categoria"
    onChange={(e) => setCategory(e.target.value)}
    sx={{ color: "#e0e0e0", backgroundColor: "#333" }}
  >
    {categories.map((cat) => (
      <MenuItem key={cat.id} value={cat.name}>
        {cat.name}
      </MenuItem>
    ))}
  </Select>
</FormControl>


        {/* Data per il post */}
        <TextField
          label="Data del Post"
          type="date"
          fullWidth
          value={postDate}
          onChange={(e) => setPostDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
            sx: { color: "#e0e0e0" },
          }}
          sx={{ mb: 2, backgroundColor: "#333", input: { color: "#e0e0e0" } }}
        />

        {/* Select per l'ora del post */}
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="post-time-label" sx={{ color: "#e0e0e0" }}>
            Ora del Post
          </InputLabel>
          <Select
            labelId="post-time-label"
            value={postTime}
            label="Ora del Post"
            onChange={(e) => setPostTime(e.target.value)}
            sx={{ color: "#e0e0e0", backgroundColor: "#333" }}
          >
            {timeOptions.map((time) => (
              <MenuItem key={time} value={time}>
                {time}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{
            py: 1.5,
            backgroundColor: "#FF4081",
            "&:hover": { backgroundColor: "#e73370" },
          }}
        >
          Posta
        </Button>
      </form>
    </Paper>
  );


}


function CategoryForm() {
 const [categoryName, setCategoryName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [uploading, setUploading] = useState(false);

  // Quando viene selezionata l'immagine, la carica subito e ne mostra la preview
  const handleFileChange = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setUploading(true);
      try {
        const uploadResponse = await uploadImageToImgbb(file);
        const link = uploadResponse.data.url || uploadResponse.data.data.url;
        setImageUrl(link);
        console.log("Immagine caricata:", link);
      } catch (error) {
        console.error("Errore nel caricamento dell'immagine:", error);
      } finally {
        setUploading(false);
      }
    }
  };

  // Quando viene premuto "Aggiungi", invia il form con il nome e il link dell'immagine
  const handleSubmit = (e) => {
  e.preventDefault();
  console.log("Aggiungi categoria:", categoryName, imageUrl);

  // Costruiamo l'oggetto dati da inviare al server
  const payload = {
    name: categoryName,
    urlimg: imageUrl
  };

  axios.post('https://hiddenvideos.vip/api/add-category', payload, {
    headers: {
      'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
      'Content-Type': 'application/json'
    }
  })
  .then(response => {
    console.log('Categoria aggiunta con successo:', response.data);
    // Puliamo i campi del form
    setCategoryName('');
    setImageUrl('');
  })
  .catch(error => {
    console.error('Errore durante l\'aggiunta della categoria:', error);
  });
};

  return (
    <Paper
      sx={{
        p: 2,
        backgroundColor: "#1a1a1a",
        color: "#e0e0e0",
        maxWidth: 600,
        mx: "auto",
      }}
    >
      <Typography variant="h5" sx={{ mb: 2 }}>
        Aggiungi Categoria
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Nome Categoria"
          variant="filled"
          fullWidth
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
          sx={{
            mb: 2,
            backgroundColor: "#333",
            input: { color: "#e0e0e0" },
          }}
        />
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Button
            variant="contained"
            component="label"
            sx={{ backgroundColor: "#FF4081" }}
          >
            {uploading ? "Caricamento in corso..." : "Carica Immagine"}
            <input type="file" hidden onChange={handleFileChange} />
          </Button>
          {imageUrl && (
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="body2">Anteprima Immagine:</Typography>
              <img
                src={imageUrl}
                alt="Anteprima"
                style={{ maxWidth: "100%", borderRadius: "8px" }}
              />
            </Box>
          )}
          <Button
            type="submit"
            variant="contained"
            sx={{ backgroundColor: "#FF4081" }}
          >
            Aggiungi
          </Button>
        </Box>
      </form>
    </Paper>
  );
}


function PostsTable() {
const [posts, setPosts] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);

  // Carica tutti i post al montaggio del componente
  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = () => {
    axios.get('https://hiddenvideos.vip/api/programming', {
      headers: {
        'Authorization': '=1l<*uq8Ks8Fhxg5-]b'
      }
    })
    .then(response => {
      setPosts(response.data);
    })
    .catch(error => {
      console.error("Errore nel recupero dei post:", error);
    });
  };

  const handleOpenModal = (post) => {
    setSelectedPost(post);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setSelectedPost(null);
    setOpenModal(false);
  };

  console.log(posts)

  const handleDelete = (postId) => {
    axios.delete(`https://hiddenvideos.vip/api/programming/${postId}`, {
      headers: {
        'Authorization': '=1l<*uq8Ks8Fhxg5-]b'
      }
    })
    .then(response => {
      console.log("Post eliminato con successo:", response.data);
      // Aggiorna la lista dei post dopo l'eliminazione
      fetchPosts();
      handleCloseModal();
    })
    .catch(error => {
      console.error("Errore durante l'eliminazione del post:", error);
    });
  };

  return (
    <>
    <TableContainer
        component={Paper}
        sx={{
          backgroundColor: "#1a1a1a",
          maxWidth: 600,
          mx: "auto",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: "#e0e0e0" }}>Nome</TableCell>
              <TableCell sx={{ color: "#e0e0e0" }}>Data</TableCell>
              <TableCell sx={{ color: "#e0e0e0" }}>Azioni</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {posts.map((post) => (
              <TableRow key={post.id}>
                <TableCell sx={{ color: "#e0e0e0" }}>{post.name}</TableCell>
                <TableCell sx={{ color: "#e0e0e0" }}>{post.date}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    onClick={() => handleOpenModal(post)}
                    sx={{ backgroundColor: "#FF4081", mr: 1, fontWeight: 700  }}
                  >
                     <VisibilityIcon />
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => handleDelete(post.id)}
                    sx={{ backgroundColor: "red", fontWeight: 700  }}
                  >
                    <DeleteIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>


      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "#1a1a1a",
            color: "#e0e0e0",
            border: "2px solid #FF4081",
            boxShadow: 24,
            p: 4,
            maxWidth: 400,
            width: "90%",
          }}
        >
          {selectedPost && (
           <>
  {selectedPost && selectedPost.type === "onlyfans_ppv" ? (
    <>
      <img
        src={selectedPost?.linkImg}
        alt="Anteprima"
        style={{ maxWidth: "100%", borderRadius: "8px" }}
      />
      <Typography variant="body1" sx={{ mb: 2 }}>
        Tipologia: {selectedPost?.type || "N/A"}
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Categoria: {selectedPost?.category || "N/A"}
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Nome: {selectedPost?.name || "N/A"}
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Link: {selectedPost?.link || "N/A"}
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Descrizione: {selectedPost?.dati?.descrizione || "N/A"}
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Collaborazione: {selectedPost?.dati?.colab || "N/A"}
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Nazionalità: {selectedPost?.dati?.nation || "N/A"}
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Durata: {selectedPost?.dati?.durata || "N/A"}
      </Typography>

      <Typography variant="body1" sx={{ mb: 2 }}>
        Post Universe69.pro: {selectedPost?.postOnU == true ? "Si" : "No" || "N/A"}
      </Typography>
    </>
  ) : (
    <>
      <img
        src={selectedPost?.linkImg}
        alt="Anteprima"
        style={{ maxWidth: "100%", borderRadius: "8px" }}
      />
      <Typography variant="body1" sx={{ mb: 2 }}>
        Tipologia: {selectedPost?.type || "N/A"}
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Categoria: {selectedPost?.category || "N/A"}
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Nome: {selectedPost?.name || "N/A"}
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Link: {selectedPost?.link || "N/A"}
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        GB: {selectedPost?.dati?.gb || "N/A"}
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Post Universe69.pro: {selectedPost?.postOnU == true ? "Si" : "No" || "N/A"}
      </Typography>
    </>
  )}
  <Button
    variant="contained"
    onClick={handleCloseModal}
    sx={{ backgroundColor: "#FF4081" }}
  >
    Chiudi
  </Button>
</>

          )}
        </Box>
      </Modal>
      </>
  );
}

// Componente principale che gestisce le Tab
export default function AdminTabs() {
  const [selectedTab, setSelectedTab] = useState(0);

const [user, setUser] = useState(null);
  const [loadingToken, setLoadingToken] = useState(true);
  const routerLocation = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Esempio: recupero del token dalla query string o da localStorage
    const params = new URLSearchParams(routerLocation.search);
    const tokenFromQuery = params.get("token");
    const token = tokenFromQuery || localStorage.getItem("token");
    const expectedToken = "TDGwJ7LLBJ7ZmtEAkIA4TKyHBeG48Xta0C5SEZqf5Z9hW7DvWU";

    if (token && token === expectedToken) {
      setUser({ telegramId: "valoreDerivatoDalToken" });
    } else {
      console.error("Token non valido o mancante");
      setUser(null);
    }
    setLoadingToken(false);
  }, [routerLocation.search]);

  useEffect(() => {
    if (!loadingToken && !user) {
      // Se non c'è un utente valido, effettua il redirect
      navigate("/"); // Sostituisci "/login" con la route desiderata
    }
  }, [loadingToken, user, navigate]);


  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        centered
        textColor="inherit"
        indicatorColor="secondary"
        sx={{ backgroundColor: "#333" }}
      >
        <Tab label="+ Post" sx={{color: "#FF4081", fontWeight: 700 }}/>
        <Tab label="+ Categoria" sx={{color: "#FF4081", fontWeight: 700 }}/>
        <Tab label="Visualizza Posts" sx={{color: "#FF4081", fontWeight: 700 }} />
      </Tabs>
      <Box sx={{ p: 2 }}>
        {selectedTab === 0 && <PosterForm />}
        {selectedTab === 1 && <CategoryForm />}
        {selectedTab === 2 && <PostsTable />}
      </Box>
    </Box>
  );
}
