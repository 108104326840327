import React, { useState, useEffect } from 'react';
import './css/Card.css';
import { Card, Typography, Button, Box, Container, Grid, Link, useMediaQuery } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom'; // Importa il hook useNavigate
import CryptoJS from 'crypto-js';
import PersonIcon from "@mui/icons-material/Person";
import CategoryIcon from "@mui/icons-material/Category";
import AccessTimeIcon from "@mui/icons-material/AccessTime";


function timeAgo(dateString) {
  const date = new Date(dateString);
  const now = new Date();
  const seconds = Math.floor((now - date) / 1000);

  const intervals = {
    year: 31536000,
    month: 2592000,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1,
  };

  for (const [unit, value] of Object.entries(intervals)) {
    const interval = Math.floor(seconds / value);
    if (interval >= 1) {
      return `${interval} ${unit}${interval > 1 ? 's' : ''} ago`;
    }
  }
  return 'just now';
}

function md5ToBase64(url) {
  // Step 1: Convert the URL to an MD5 hash
  const md5Hash = CryptoJS.MD5(url).toString();
  
  // Step 2: Create an array with the hash and the additional value
  const array = [md5Hash, "22dcbe26d606977f9484257ea466af9254979b97"];
  
  // Step 3: Convert the array to a JSON string
  const jsonString = JSON.stringify(array);
  
  // Step 4: Encode the JSON string to base64
  const base64String = btoa(jsonString);
  
  return base64String;
}


const CardCustom = ({ title, category, time, imageUrl, id }) => {
  const timetogo = timeAgo(time);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate(); // Inizializza il hook useNavigate

    const handleClick = () => {
      let param = md5ToBase64(`https://hiddenvideos.vip/card/${id}`)
      navigator.clipboard.writeText(`https://hiddenvideos.vip/card/${id}?u=${param}`).then(() => {
        setOpen(true);
      });
    };

    const handleClose = () => {
      setOpen(false);
    };

  const [openDrawer, setOpenDrawer] = useState(false);

   const [data, setData] = useState([]);
   const [loading, setLoading] = useState(false);

   const handleClickOpen = async () => {
    setLoading(true);
    try {
      const response = await fetch(`https://hiddenvideos.vip/api/more?q=${title}`, {
        method: 'GET',
        headers: {
          'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      setData(result);
      setOpenDrawer(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleCardClick = (id) => {
    navigate(`/category/${id}`); // Naviga alla rotta /category/:id
  };

 const openlink = (profile) => {
  navigate(`/?searchActive=true&profile=${encodeURIComponent(title)}`);
};


  const [categories, setCategories] = useState([]);
  const [categoryName, setCategoryName] = useState(category);
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`https://hiddenvideos.vip/api/categories`, {
        method: 'GET',
        headers: {
          'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
          'Content-Type': 'application/json'
        }
      });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        setCategories(result);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    fetchCategories();
  }, []);

  const getCategoryName = (cats) =>{
    if (categories.length > 0) {
      const categoryObj = categories.find(cat => cat.id === cats);

      if (categoryObj) {
        return categoryObj.name
      }
      return "Uncategory"
    }
  }
  // Update category name based on category ID
  useEffect(() => {
    if (categories.length > 0) {
      const categoryObj = categories.find(cat => cat.id === category);
      if (categoryObj) {
        setCategoryName(categoryObj.name);
      }
    }
  }, [categories, category]);
  const isMobile = useMediaQuery('(max-width:600px)');


  return (
<Card
  sx={{
    width: { xs: '90%', sm: 400 },
    height: { xs: 'auto', sm: 670 },
    borderRadius: 3,
    overflow: "hidden",
    boxShadow: "0 4px 12px rgba(0,0,0,0.3)",
    backgroundColor: "#2c2c2c",
    position: "relative",
    margin: 'auto',
  }}
>
  {/* Sfondo blurato */}
  <Box
    sx={{
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage: `url(${imageUrl})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      filter: "blur(8px)",
      zIndex: 1,
      opacity: 0.7,
    }}
  />

  {/* Header con titolo e info */}
  <Box
    sx={{
      backgroundColor: 'rgba(0,0,0,0.8)',
      color: '#fff',
      p: 3,
      position: "relative",
      zIndex: 2,
      mx: 2,
      mt: 2,
      borderRadius: "8px",
    }}
  >
    <Typography
      variant="h5"
      sx={{
        fontWeight: 600,
        mb: 1,
        fontSize: { xs: '1rem', sm: '1.2rem', md: '1.5rem' }
      }}
    >
      {title}
    </Typography>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 2,
        flexWrap: 'wrap'
      }}
    >
      <Button
        onClick={() => handleCardClick(category)}
        sx={{
          color: "#ccc",
          textTransform: "none",
          fontSize: { xs: '14px', sm: '16px' },
          py: 0,
          px: 1,
        }}
      >
        <CategoryIcon sx={{ mr: 0.5 }} /> {getCategoryName(category)}
      </Button>
      <Button
        sx={{
          color: "#ccc",
          textTransform: "none",
          fontSize: { xs: '14px', sm: '16px' },
          py: 0,
          px: 1,
        }}
      >
        <AccessTimeIcon sx={{ mr: 0.5 }} /> {timetogo}
      </Button>
    </Box>
  </Box>

  {/* Wrapper generale */}
  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    {/* Immagine principale */}
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        p: 3,
        position: "relative",
        zIndex: 2,
      }}
    >
      {imageUrl && (
        <img
          src={imageUrl}
          alt={title}
          style={{
            width: "100%",
            height: isMobile ? "200px" : "350px",
            objectFit: "cover",
            borderRadius: "8px",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.3)",
          }}
        />
      )}
    </Box>

    {/* Pulsanti */}
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        gap: 1,
        p: 1,
        position: "relative",
        zIndex: 2,
      }}
    >
      <Link
        to={`https://hiddenvideos.vip/?searchActive=true&profile=${encodeURIComponent(title)}`}
        style={{ textDecoration: "none" }}
      >
        <Button
          onClick={() => openlink?.(id)}
          variant="contained"
          sx={{
            backgroundColor: "rgb(13,13,13)",
            color: "#fff",
            textTransform: "none",
            fontWeight: 500,
            fontSize: "16px",
            borderRadius: "8px",
            px: 6,
            py: 1.5,
            "&:hover": {
              backgroundColor: "#1e1e1e",
            },
          }}
        >
          <PersonIcon sx={{ mr: 0.5 }} />
          Open Profile
        </Button>
      </Link>
    </Box>
  </Box> {/* Chiusura del Wrapper generale */}

  {/* Snackbar */}
  <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
    <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
      Link copied to clipboard!
    </Alert>
  </Snackbar>
</Card>
  );
}







export default CardCustom;



