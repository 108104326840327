import React from "react";
import { Outlet } from "react-router-dom";
import MenuComponent from './component/menu';
import { Container } from "@mui/material";
import Footer from './component/footer';

export default function LayoutDefault() {
  return (
    <>
      <MenuComponent />
      <Container maxWidth={false} disableGutters>
        <Outlet />
      </Container>
      <Footer />
    </>
  );
}

