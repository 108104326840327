import React, { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import { useLocation, useParams } from 'react-router-dom';
import {useMediaQuery, Button} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

function md5ToBase64(url) {
  // Step 1: Convert the URL to an MD5 hash
  const md5Hash = CryptoJS.MD5(url).toString();
  
  // Step 2: Create an array with the hash and the additional value
  const array = [md5Hash, "22dcbe26d606977f9484257ea466af9254979b97"];
  
  // Step 3: Convert the array to a JSON string
  const jsonString = JSON.stringify(array);
  
  // Step 4: Encode the JSON string to base64
  const base64String = btoa(jsonString);
  
  return base64String;
}



function useQuery() {
  return new URLSearchParams(useLocation().search);
}

//846327 all not lussuria
function shortenLinkWithLinkvertise(linkToShorten, userid) {
    const randomInt = Math.floor(Math.random() * 1000) + 1;
    const baseUrl = `https://link-to.net/${userid}/${randomInt}/dynamic/`;
    const encodedLink = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(linkToShorten));
    const linkvertiseUrl = `${baseUrl}?r=${encodedLink}`;
    return linkvertiseUrl;
}

const UnlockContent = ({ id, finalLink, type }) => {
  const [progress, setProgress] = useState([]);
  const [contentUnlocked, setContentUnlocked] = useState(false);
  const [userIp, setUserIp] = useState('');
  const [paycoin, setPayCoin] = useState('');
  const [isPaid, setIsPaid] = useState(false);
const [linkvertiseId, setLinkvertiseId] = useState('');
  const secret = '=1l<*uq8Ks8Fhxg5-]b';
  const steps = ['link1', 'link2'];  // Modificato a due step
  const hash = localStorage.getItem('stepHash');
  const storedValue2 = localStorage.getItem('e5e486979728b4f6be6401d24967ecc0');
  if (storedValue2 == 5) {
    const storedValue = localStorage.getItem('fd064b41a01ca9a573feb720493a334d');
    localStorage.setItem('fd064b41a01ca9a573feb720493a334d', Number(storedValue) + 1);
    localStorage.setItem('e5e486979728b4f6be6401d24967ecc0', "0");
  }
  let query = useQuery();
  let uparams = query.get('u');
  function base64Decode(str) {
      try {
        return JSON.parse(atob(str));
      } catch (e) {
        return null;
      }
  }

  const [open, setOpen] = useState(false);
   const validHashes = [
      "22368285a4cba5c286615b799a814b3ee7dae0a4",
      "22dcbe26d606977f9484257ea466af9254979b97"
    ];
    useEffect(() => {
     let isReferrerValid = false;
    let isUParamsValid = false;


    // Controllo del referrer
    const referrer = document.referrer;
    if (referrer === "https://linkvertise.com/" || referrer === "https://www.linkvertise.com/") {
      isReferrerValid = true;
    }

    // Controllo dei parametri `u`
    if (uparams) {
      const decodedParams = base64Decode(uparams);
      if (decodedParams && decodedParams.length >= 2) {
        const [siteLink, hash] = decodedParams;


        const currentURL = window.location.href.split("?u=")[0];
        const currentHash = CryptoJS.MD5(currentURL).toString();

        const validHashes = [
          "22368285a4cba5c286615b799a814b3ee7dae0a4",
          "22dcbe26d606977f9484257ea466af9254979b97"
        ];

        // Verifica se il currentURL inizia con siteLink o se l'hash è valido
        if (currentHash == siteLink) {
          if (validHashes.includes(hash)) {
            isUParamsValid = true;
          }
        } 
      } 
    } 

    // Mostra il messaggio di avviso solo se entrambi i controlli falliscono
    if (!isReferrerValid && !isUParamsValid) {
      setOpen(true);
      localStorage.removeItem('stepHash');
      localStorage.removeItem('progress');
    } 
  }, []);

const handleClose = () => {
        setOpen(false);
    };

  useEffect(() => {
    const fetchUserIp = async () => {
      try {
        const response = await fetch('https://hiddenvideos.vip/api/get-ip');
        const data = await response.json();
        setUserIp(data.ip);
      } catch (error) {
        console.error('Error fetching IP:', error);
      }
    };

    fetchUserIp();
  }, []);

  useEffect(() => {
    const loadProgress = () => {
      const savedProgress = localStorage.getItem('progress');
      if (savedProgress) {
        setProgress(JSON.parse(savedProgress));
      }
    };

    loadProgress();
  }, []);

  useEffect(() => {
    if (!userIp || !hash) {
      return;
    }

    const validateHash = () => {
      const savedHash = localStorage.getItem('stepHash');
      const currentStep = getStepFromHash();
      const expectedHash = generateHash(userIp, currentStep, id);
      const referrer = document.referrer;
      if (referrer === "https://linkvertise.com/" || referrer === "https://www.linkvertise.com/") {
     
      
        if (savedHash === expectedHash) {
          setProgress(prevProgress => {
            if (!prevProgress.includes(currentStep)) {
              const updatedProgress = [...prevProgress, currentStep];
              localStorage.setItem('progress', JSON.stringify(updatedProgress));
              return updatedProgress;
            }
            return prevProgress;
          });

          if (currentStep === 'link2') {
            setContentUnlocked(true);
          }
      }
      }
    };

    validateHash();
  }, [hash, userIp]);

  const generateHash = (ip, step, id) => {
    const combinedString = `${ip}:${step}:${id}:${secret}`;
    return CryptoJS.SHA256(combinedString).toString(CryptoJS.enc.Hex);
  };

const loadLinkvertiseId = async () => {
    try {
      const response = await fetch('https://hiddenvideos.vip/api/linkvertise', {
      method: 'GET',
      headers: {
        'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
        'Content-Type': 'application/json'
      }
    });
      if (!response.ok) throw new Error('Errore nel recupero dell’ID di Linkvertise');
      const data = await response.json();
      if (data.linkvertiseId) {
        setLinkvertiseId(data.linkvertiseId);
      }
    } catch (error) {
      console.error(error);
    }
  };

  loadLinkvertiseId();

  const startStep = (step) => {
    if (!userIp) {
      return;
    }

    const hash = generateHash(userIp, step, id);
    localStorage.setItem('stepHash', hash);
    let param = md5ToBase64(`https://hiddenvideos.vip/card/${id}`)
    const link = `https://hiddenvideos.vip/card/${id}?u=${param}`;
    
    let fn;
    fn = shortenLinkWithLinkvertise(link,linkvertiseId);

    window.open(fn, "_blank");
  };

  const handleButtonClick = () => {
    const nextStep = getNextStep();
    const storedValue = localStorage.getItem('fd064b41a01ca9a573feb720493a334d');

    if (storedValue && Number(storedValue) > 0) {
      window.open(finalLink, "_blank");
      localStorage.setItem('fd064b41a01ca9a573feb720493a334d', Number(storedValue) - 1);
    } else {
      if (contentUnlocked) {
        const storedValue2 = localStorage.getItem('e5e486979728b4f6be6401d24967ecc0');
        localStorage.setItem('e5e486979728b4f6be6401d24967ecc0', Number(storedValue2) + 1);
        window.open(finalLink, "_blank");
        localStorage.removeItem('stepHash');
        localStorage.removeItem('progress');


        window.location.reload();
      } else {
        startStep(nextStep);
      }
    }
  };

  const getNextStep = () => {
    if (progress.includes('link2')) return 'link2';
    if (progress.includes('link1')) return 'link2';
    return 'link1';
  };

  const getStepFromHash = () => {
    if (!userIp) {
      return 'link1';
    }
    return steps.find(step => generateHash(userIp, step, id) === hash) || 'link1';
  };

  useEffect(() => {
    const storedValue = localStorage.getItem('fd064b41a01ca9a573feb720493a334d');

    if (storedValue && Number(storedValue) > 0) {
      setIsPaid(true);
    } else {
      setIsPaid(false);
    }

    setPayCoin(isPaid
      ? 'Open Link'
      : contentUnlocked
        ? 'Open Final Link'
        : progress.includes('link1')
          ? 'Go to Step 2'
          : 'Go to Step 1');
  }, [progress, contentUnlocked, userIp, id, hash, isPaid]);
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <span>
    <Button
      variant="contained"
      sx={{
        fontWeight: 500,
        fontSize: "16px",
        borderRadius: "8px",
        color: "white",
        backgroundColor: "rgb(13,13,13)",
        '&:hover': {
          backgroundColor: '#1e1e1e',
          borderColor: '#333',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)'
        },
        width: 200
      }}
      onClick={handleButtonClick}
    >
      <svg xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '4px' }} width="20" height="20" viewBox="0 0 1000 1000" className="mr-2 h-4 w-4 accent-white">
        <path fill="currentColor" d="M512 64C264.571 64 64 264.571 64 512s200.571 448 448 448 448-200.571 448-448S759.429 64 512 64zm232.553 606.424a13.861 13.861 0 01-13.883 13.884h-58.51a13.861 13.861 0 01-13.884-13.884V490.183a1.77 1.77 0 00-2.976-1.24L531.338 612.905a27.684 27.684 0 01-38.924 0L368.452 488.943a1.797 1.797 0 00-2.976 1.24v180.24a13.861 13.861 0 01-13.883 13.885h-58.51a13.861 13.861 0 01-13.884-13.884V353.576a13.861 13.861 0 0113.883-13.884h40.164a28.013 28.013 0 0119.586 8.182l154.21 154.209a6.736 6.736 0 009.669 0l154.209-154.21a27.577 27.577 0 0119.586-8.18h40.164a13.861 13.861 0 0113.883 13.883z"></path>
      </svg>
      {paycoin}
    </Button>
     <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
                    You tried to bypass the link!
                </Alert>
            </Snackbar>
    </span>
  );
};

export default UnlockContent;
