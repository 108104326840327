import React, { useState } from 'react';
import { Form, Input, Button, Card, message } from 'antd';

export default function AddCard() {
  const [form] = Form.useForm();
  const [previewData, setPreviewData] = useState(null);

  const onFinish = async (values) => {
    try {
      const response = await fetch('https://hiddenvideos.vip/api/add-card', {
        method: 'POST',
        headers: {
          'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(values)
      });
      const data = await response.json();
      if (!response.ok) throw new Error(data.message);
      message.success('Card aggiunta con successo');
      form.resetFields();
      setPreviewData(null);
    } catch (error) {
      message.error('Errore durante la creazione della card');
    }
  };

  const onValuesChange = (changedValues, allValues) => {
    setPreviewData(allValues);
  };

  return (
    <div style={{ padding: 20 }}>
      <h2>Aggiungi Card</h2>
      <Form form={form} layout="vertical" onFinish={onFinish} onValuesChange={onValuesChange}>
        <Form.Item
          name="title"
          label="Titolo"
          rules={[{ required: true, message: 'Inserisci il titolo' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="category"
          label="Categoria"
          rules={[{ required: true, message: 'Inserisci la categoria' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="imageUrl"
          label="URL Immagine"
          rules={[{ required: true, message: 'Inserisci l\'URL dell\'immagine' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="link"
          label="Link"
          rules={[{ required: true, message: 'Inserisci il link' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="type"
          label="Tipo"
          rules={[{ required: true, message: 'Inserisci il tipo' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Aggiungi Card
          </Button>
        </Form.Item>
      </Form>
      {previewData && (
        <div style={{ marginTop: 20 }}>
          <h3>Anteprima Card</h3>
          <Card
            title={previewData.title || "Titolo della Card"}
            cover={
              previewData.imageUrl ? (
                <img alt="card" src={previewData.imageUrl} style={{ height: 200, objectFit: 'cover' }} />
              ) : null
            }
          >
            <p><strong>Categoria:</strong> {previewData.category || "Categoria"}</p>
            <p><strong>Link:</strong> {previewData.link || "Link"}</p>
            <p><strong>Tipo:</strong> {previewData.type || "Tipo"}</p>
          </Card>
        </div>
      )}
    </div>
  );
}

